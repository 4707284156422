import { PLAN_WITH_SERVICES } from '@op-platform/backend/founder';
import { useMemo } from 'react';

import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups';
import { IconPlus } from '@/components/icons/icon-plus';
import { Button, ButtonProps } from '@/components/ui/button';
import { Option } from '@/lib/global-types';
import {
  cn,
  convertServicesForUI,
  isBlankOption,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from '@/lib/utils';

type Props = {
  title: string;
  payload: Option;
} & ButtonProps;
function OfferButtonInner({ title, payload, className, ...rest }: Props) {
  const { services, plan } = payload;

  const allServices = useMemo(() => {
    if (plan) return convertServicesForUI(PLAN_WITH_SERVICES[plan]);
    return convertServicesForUI(services);
  }, [plan, services]);

  const price = allServices.reduce((acc, service) => acc + service.price, 0);

  const maximumDuration = allServices.sort(
    (a, b) => sizeToMountCount(b.size) - sizeToMountCount(a.size),
  )[0]?.size;

  return (
    <Button {...rest} type='button' size={'xl'} className={cn('group', className)}>
      <div className="flex flex-col items-center justify-center gap-6">
        <h2>{title}</h2>
        {allServices.map(service => {
          const counts = sizeToMountCount(service.size);
          const dots = [...Array(counts)].map((_, i) => (
            <FeatureDot
              className="group-[.is-active]:border-white group-[.is-active]:bg-white"
              key={i}
            />
          ));
          return (
            <FeatureContainer
              key={`${service.type}-${service.size}`}
              className="group-[.is-active]:border-white group-[.is-active]:after:bg-white">
              <FeatureGroup className="before:group-[.is-active]:bg-white first:before:group-[.is-active]:bg-white last:after:group-[.is-active]:bg-white">
                <span className="uppercase">{service.type.charAt(0)}</span>
              </FeatureGroup>
              <FeatureGroup className="gap-2 before:group-[.is-active]:bg-white first:before:group-[.is-active]:bg-white last:after:group-[.is-active]:bg-white">
                {dots.length > 0 && dots}
              </FeatureGroup>
            </FeatureContainer>
          );
        })}

        <div className="flex flex-col items-center">
          <span>{sizeToMonth(maximumDuration!)}</span>
          <span>{priceFormatter.format(price)}</span>
        </div>
      </div>
    </Button>
  );
}

export const OfferButton = ({
  payload,
  onClick,
  title,

  ...rest
}: Props) => {
  if (isBlankOption(payload))
    return (
      <Button
        type="button"
        size={'xl'}
        className="group flex flex-col items-center justify-center gap-6"
        onClick={e => {
          onClick?.(e);
        }}>
        <h2>{title}</h2>

        <div className="flex items-center justify-center rounded-full border border-black p-5 group-hover:border-white">
          <IconPlus />
        </div>
      </Button>
    );
  return (
    <OfferButtonInner
      title={title}
      payload={payload}
      onClick={onClick}
      {...rest}
    />
  );
};
