/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from 'motion/react';
import { Fragment } from 'react';

import { cn } from '@/lib/utils';

import ProjectCode from './project-code';

export default function ProjectFeed({
  projects,
  plan,
  delay = 0,
}: {
  projects: any[];
  plan?: boolean;
  delay?: number;
}) {
  return (
    <div className="mx-auto flex flex-col gap-60 px-2">
      {projects.map((project, index) => (
        <div
          key={`${index}-${project.name}`}
          className="flex flex-col items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: 'easeInOut',
              delay: delay,
              duration: 0.2,
            }}
            className="mb-10">
            <ProjectCode
              step={project.step}
              tags={project.tags}
              duration={project.duration}
              plan={plan}
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: 'easeInOut',
              delay: delay + 0.05,
              duration: 0.2,
            }}
            className="mb-20 flex flex-col">
            {project.description.map((line: string, index: number) => (
              <p key={index} className="text-center md:text-lg">
                {line.split('\n').map((subline, subindex) => (
                  <Fragment key={subindex}>
                    {subline}
                    <br />
                  </Fragment>
                ))}
              </p>
            ))}
            {project.link && (
              <a
                href={project.link.href}
                target="_blank"
                className="mt-2 text-center underline underline-offset-2 md:text-lg"
                rel="noreferrer">
                {project.link.text}
              </a>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: 'easeInOut',
              delay: delay + 0.1,
              duration: 0.2,
            }}
            className="grid w-full auto-rows-fr grid-cols-2 gap-4">
            {project.media.map((media: any) => (
              <div
                key={media.src}
                className={cn(
                  'border-border overflow-hidden rounded-2xl border md:rounded-3xl',
                  media.width == 'half' ? 'col-span-1' : 'col-span-2',
                )}>
                {media.type === 'img' ? (
                  <img
                    src={`/feed/${project.name.toLowerCase().replaceAll(' ', '-')}/${media.src}`}
                    alt={project.name}
                    loading="lazy"
                    className={cn(
                      'size-full object-cover',
                      media.width == 'full'
                        ? 'aspect-[46/25]'
                        : 'aspect-[9/10]',
                    )}
                  />
                ) : (
                  <video
                    src={`/feed/${project.name.toLowerCase().replaceAll(' ', '-')}/${media.src}`}
                    autoPlay
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    disableRemotePlayback
                    className={cn(
                      'aspect-video size-full object-cover',
                      media.width == 'full'
                        ? 'aspect-[46/25]'
                        : 'aspect-[9/10]',
                    )}
                  />
                )}
              </div>
            ))}
          </motion.div>
        </div>
      ))}
    </div>
  );
}
