import { Designer } from '@/lib/global-types';

import { FeatureContainer, FeatureGroup } from '../feature-groups';
import { Button, ButtonProps } from '../ui/button';

type BottomStyleVariant = 'show_updates' | 'show_services';
type Props = {
  designer: Designer;
  isAssigned?: boolean;
  bottomStyle?: BottomStyleVariant;
} & ButtonProps;

export function AssignUser({
  designer,
  isAssigned = false,
  bottomStyle = 'show_services',
  ...rest
}: Props) {
  const renderBottom = () => {
    switch (bottomStyle) {
      case 'show_services': {
        const services = designer.services;

        return (
          <FeatureContainer>
            {services?.map(service => {
              return (
                <FeatureGroup key={service} className="uppercase">
                  {service.charAt(0)}
                </FeatureGroup>
              );
            })}
          </FeatureContainer>
        );
      }
      case 'show_updates': {
        throw new Error('Not implemented');
      }
    }
  };

  return (
    <Button
      className="flex-col gap-4"
      variant={isAssigned ? 'black' : 'default'}
      size={'lg'}
      {...rest}>
      <img
        className="h-10 w-10 rounded-full border border-[#00000014]"
        src={designer.user.image || '/blank-profile.jpeg'}
        alt={`${designer.name} avatar`}
      />

      {designer.name}
      {renderBottom()}
    </Button>
  );
}
