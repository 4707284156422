import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { motion } from 'motion/react';
import { useEffect } from 'react';

import { FadeSection } from '@/components/fade-section';
import { FEATURE_BRANDS_LOGOS } from '@/components/featured-brands';
import { OPLogo } from '@/components/op-logo';
import { OPLogoLarge } from '@/components/op-logo-large';
import { Button } from '@/components/ui/button';
import { getUserTypeFromLocalStorage } from '@/lib/utils';
import { animation$ } from '@/store/page.state';

export const Route = createFileRoute('/')({
  beforeLoad: () => {
    const { type } = getUserTypeFromLocalStorage();

    switch (type) {
      case 'FOUNDER':
        return redirect({ to: '/dashboard/founder', replace: true });
      case 'DESIGNER':
        return redirect({ to: '/dashboard/designer', replace: true });
      case 'ADMIN':
        return redirect({
          to: '/dashboard/admin',
          replace: true,
          search: { filter: undefined },
        });
    }
  },
  component: HomeComponent,
});

export function HomeComponent() {
  useEffect(() => {
    // prefetch featured brands
    FEATURE_BRANDS_LOGOS.forEach(logo => {
      const image = new Image();
      image.src = logo;
    });
  }, []);

  return (
    <main className="flex h-full min-h-svh flex-col items-center justify-center">
      <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogoLarge />
        </Link>
      </header>

      <motion.section className="flex size-full flex-1 items-center justify-center overflow-hidden">
        <FadeSection>
          <Link
            to="/new/founder"
            className="mt-10 inline-block"
            onClick={() => animation$.set('slide-up')}>
            <Button
              size="lg"
              className="size-[310px] overflow-hidden !border-hidden !bg-transparent transition-all duration-200 hover:scale-105">
              <video
                src="/anarchy_start.mp4"
                autoPlay
                loop
                muted
                playsInline
                className="size-full -translate-y-0.5 object-cover"
              />
            </Button>
          </Link>
        </FadeSection>
      </motion.section>

      <footer className="fixed bottom-6 md:left-1/2 md:mb-0 md:-translate-x-1/2">
        <Link to="/" className="mx-auto inline-block">
          <OPLogo />
        </Link>
      </footer>
    </main>
  );
}
