import { PLAN_WITH_SERVICES } from '@op-platform/backend/founder';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo } from 'react';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups';
import { Button } from '@/components/ui/button';
import {
  capitalize,
  convertServicesForUI,
  priceFormatter,
  sizeToMountCount,
  timeFormatter,
} from '@/lib/utils';

export const Route = createFileRoute(
  '/dashboard/admin/founders/$founderId/invoice',
)({
  loader: async ({ params }) => {
    const founder = await trpcClientUtils.admin.founder.get.ensureData({
      id: parseInt(params.founderId),
    });

    return { founder };
  },

  component: function Page() {
    const { founder } = Route.useLoaderData();

    const option = founder.selectedOption!;
    const invoice = founder.invoice!;

    const allServices = useMemo(() => {
      if (option.plan)
        return convertServicesForUI(PLAN_WITH_SERVICES[option.plan]);
      else return convertServicesForUI(option.services);
    }, [option.plan, option.services]);

    const price = allServices.reduce((acc, service) => acc + service.price, 0);

    const maxDurationService = allServices.sort(
      (a, b) => sizeToMountCount(b.size) - sizeToMountCount(a.size),
    )[0];

    const dotCount = sizeToMountCount(maxDurationService!.size);

    const dots = Array.from({ length: dotCount }, (_, i) => (
      <FeatureDot key={i} />
    ));

    const servicesText = allServices.map(s => capitalize(s.type)).join(' + ');

    return (
      <section>
        <div className="mx-auto flex max-w-[320px] flex-col items-center gap-10 pb-52 md:max-w-[420px]">
          <div className="flex flex-col items-center text-center">
            <p># {invoice.id}</p>
            <p>
              Issued {timeFormatter(invoice.updatedAt).format('DD MMM YYYY')}
            </p>
            <p className="text-red">
              Due {timeFormatter(invoice.due_date).format('DD MMM YYYY')}
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>From</p>
            <p>O P U F SOFTWARE HOUSE L.L.C.</p>
            <p>Unit 4A-01, Citywalk, Al Wasl,</p>
            <p>Dubai, United Arab Emirates</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <p>To</p>
            <p>{invoice.legal_name}</p>
            <p>{invoice.company_address}</p>
            <p>{invoice.country}</p>
            <p>{invoice.phone_number}</p>
            {invoice.vat_number && <p>VAT: {invoice.vat_number}</p>}
          </div>

          <Button
            size={'xl'}
            className="flex cursor-default flex-col items-center gap-10">
            <FeatureContainer>
              {allServices.map(service => {
                return (
                  <FeatureGroup key={`${service.type}-${service.size}`}>
                    {service.type.at(0)?.toUpperCase()}
                  </FeatureGroup>
                );
              })}

              {dots && <FeatureGroup className="gap-2">{dots}</FeatureGroup>}
            </FeatureContainer>

            <div>
              <h2>Services</h2>
              <p>{servicesText}</p>
            </div>

            <div>
              <h2>Total</h2>
              <p>{priceFormatter.format(price)}</p>
            </div>
          </Button>

          <div className="flex flex-col items-center text-center">
            <p>Bank details</p>
            <br />
            <p>O P U F SOFTWARE HOUSE L.L.C.</p>
            <p>IBAN: AE920330000019101408346</p>
            <p>MASHREQBANK PSC. DUBAI</p>
            <p>Account: 019101408346</p>
            <p>SWIFT: BOMLAEAD</p>
          </div>
        </div>

        <BottomNavigation
          left={<BackButton />}
          middle={
            <Button size={'default'} asChild>
              <a
                href={invoice.url || ''}
                target="_blank"
                rel="noopener noreferrer">
                Download
              </a>
            </Button>
          }
        />
      </section>
    );
  },
});
