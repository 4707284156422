import { createFileRoute } from '@tanstack/react-router';
import { motion } from 'motion/react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { ProjectItem } from '@/components/project/project-item';
import { Spinner } from '@/components/spinner';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute(
  '/dashboard/admin/designers/$designerId/assign',
)({
  loader: async ({ params }) => {
    const projects = await trpcClientUtils.project.list.fetch({
      limit: 9999999999,
      offset: 0,
    });

    const designer = await trpcClientUtils.admin.designer.get.fetch({
      id: parseInt(params.designerId),
    });

    return { projects, designer };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: RouteComponent,
});

function RouteComponent() {
  const { projects, designer } = Route.useLoaderData();
  const navigate = Route.useNavigate();

  const assignProjectMutation = trpc.admin.designer.assignProject.useMutation();

  // TODO: add infinity scroll and pagination

  return (
    <div className="flex flex-col items-center pb-40">
      <span>Assign project to {designer.name}</span>

      <div className="mx-5 flex max-w-[1080px] flex-wrap justify-center gap-4 pt-20 md:mx-10">
        {projects.map((project, index) => (
          <motion.div
            key={project.id}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            initial={{ y: 10, opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2, delay: (index + 1) * 0.05 }}>
            <ProjectItem
              onClick={async () => {
                await assignProjectMutation.mutate({
                  id: designer.id,
                  project_slug: project.slug,
                });

                toast(`Assigned project to ${designer.name}`);

                navigate({
                  to: '/dashboard/admin/designers/$designerId',
                  params: { designerId: designer.id.toString() },
                });
              }}
              project={project}
              key={project.id}
            />
          </motion.div>
        ))}
      </div>

      <BottomNavigation left={<BackButton native />} />
    </div>
  );
}
