import { createFileRoute, Link, LinkProps } from '@tanstack/react-router';
import { motion } from 'motion/react';
import { useMemo } from 'react';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CopyEmailButton } from '@/components/copy-email-button';
import { FeatureContainer, FeatureGroup } from '@/components/feature-groups';
import { Button } from '@/components/ui/button';
import { getSaveFilter } from '@/hooks/use-save-filter';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import {
  cn,
  formatTimezone,
  generateKickOffDateLabel,
  timeFormatter,
} from '@/lib/utils';

export const Route = createFileRoute(
  '/dashboard/admin/designers/$designerId/info',
)({
  loader: async ({ params }) => {
    const designer = await trpcClientUtils.admin.designer.get.fetch({
      id: parseInt(params.designerId),
    });

    return {
      designer,
    };
  },
  component: function Page() {
    const { designer } = Route.useLoaderData();
    const timezoneCurrentTime = useTimeZoneClock(designer.time_zone!);

    const isOnboardingFlow =
      designer.status === 'ONBOARDED' || designer.status === 'ONBOARDING';

    const backLink = useMemo<LinkProps['to']>(() => {
      if (designer.is_academy_user) {
        return '/dashboard/admin/academy-members';
      } else if (isOnboardingFlow) {
        return '/dashboard/admin/creatives';
      } else {
        return '/dashboard/admin/designers/$designerId';
      }
    }, [designer.is_academy_user, isOnboardingFlow]);

    return (
      <>
        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.99 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="flex flex-col items-center gap-20 pb-52 text-center">
          <div className="flex flex-col items-center gap-10">
            <FeatureContainer>
              {designer.services.map(service => {
                return (
                  <FeatureGroup key={service}>
                    {service.at(0)?.toUpperCase()}
                  </FeatureGroup>
                );
              })}
            </FeatureContainer>
            <div className="flex flex-col items-center gap-2">
              <h2>
                <CopyEmailButton
                  text={designer.name!}
                  email={designer.user.email}
                />
              </h2>
              {designer.time_zone ? (
                <h3>
                  {formatTimezone(designer.time_zone)} ･{' '}
                  {timezoneCurrentTime.format('HH:mm')}
                </h3>
              ) : null}

              <div className="flex flex-col items-center gap-2">
                {designer.links.map((link: string) => (
                  <a
                    key={link}
                    className="min-w-4 underline"
                    href={link}
                    target="_blank"
                    rel="noreferrer">
                    {link}
                  </a>
                ))}
              </div>
            </div>

            <div>
              <span>
                {generateKickOffDateLabel(designer.preferedStartDate)}
              </span>
              {/* // TODO: add requested rate */}
            </div>
          </div>

          <div className="flex flex-col gap-6">
            {designer.samples.map((sample, index) => {
              return (
                <div
                  key={sample}
                  className={cn(
                    'border-border border-primary/8 group relative w-full overflow-hidden rounded-lg border md:rounded-3xl',
                  )}>
                  <img
                    src={sample}
                    alt={`sample${index}`}
                    width={960}
                    height={540}
                    className="rounded md:rounded-[20px]"
                  />
                </div>
              );
            })}
          </div>

          <p>
            Submitted at{' '}
            {timeFormatter(designer.created_at).format('DD MMM YYYY, HH:mm')}
          </p>
        </motion.div>

        <BottomNavigation
          left={
            <BackButton
              to={backLink}
              search={{ filter: getSaveFilter() || 'next' }}
            />
          }
          middle={
            isOnboardingFlow && (
              <Button size="default" asChild>
                <Link
                  to="/dashboard/admin/designers/$designerId/rate"
                  params={{ designerId: String(designer.id) }}>
                  <p>Rate</p>
                </Link>
              </Button>
            )
          }
        />
      </>
    );
  },
});
