'use client';
import Cal, { getCalApi } from '@calcom/embed-react';
import { useQuery } from '@tanstack/react-query';
import { memo, useEffect } from 'react';

import { trpc } from '@/api/trpc';
import { CALCOM_URL } from '@/lib/constants';
import { BookingType, UserType } from '@/lib/global-types';

interface CalcomEmbedProps {
  name?: string;
  email?: string;
  callType: BookingType;
  userType: Exclude<UserType, 'ADMIN'>;
  onSuccess?: () => void;
}

const CalcomEmbed_ = ({
  name = '',
  email = '',
  userType,
  callType,
  onSuccess,
}: CalcomEmbedProps) => {
  const callSetupQuery = useQuery({
    queryKey: ['calcom'],
    queryFn: () => getCalApi({}),
  });

  const founderCallMutation = trpc.founder.bookCall.useMutation();
  const designerCallMutation = trpc.designer.bookCall.useMutation();

  useEffect(() => {
    if (callSetupQuery.data) {
      const cal = callSetupQuery.data;

      cal('ui', {
        styles: { branding: { brandColor: '#000000' } },
        hideEventTypeDetails: false,
        layout: 'month_view',
      });
      cal('on', {
        action: 'bookingSuccessful',
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: async (e: any) => {
          const { data } = e.detail;

          switch (userType) {
            case 'FOUNDER': {
              await founderCallMutation.mutateAsync({
                calcom_id: data.booking.iCalUID.split('@')[0],
                date: data.date,
                type: callType,
                duration: data.duration,
              });
              onSuccess?.();
              break;
            }

            case 'DESIGNER': {
              await designerCallMutation.mutateAsync({
                calcom_id: data.booking.iCalUID.split('@')[0],
                date: data.date,
                type: callType,
                duration: data.duration,
              });
              onSuccess?.();
              break;
            }

            default: {
              throw new Error(
                `Unsupported user type: ${userType} or not implemented`,
              );
            }
          }
        },
      });
    }
  }, [
    callSetupQuery.data,
    callType,
    designerCallMutation,
    founderCallMutation,
    onSuccess,
    userType,
  ]);

  return (
    <Cal
      calLink={`${CALCOM_URL}?name=${name}&email=${email}`}
      style={{ width: '100%', height: '100%', scrollbarWidth: 'none' }}
      config={{ layout: 'month_view' }}
    />
  );
};

export const CalcomEmbed = memo(CalcomEmbed_);
