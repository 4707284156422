import { Asset } from '@op-platform/backend/project';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useState } from 'react';

import { usePutFile, useSignFile } from '@/api/services/upload.service';
import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { AssetGenerate } from '@/lib/global-types';
import { cn } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

// this is worst code I've ever written
// I'm sorry for this
function getType(type: string): Asset['type'] {
  if (type.startsWith('video/') || type === 'VIDEO') {
    return 'VIDEO';
  }

  if (type.startsWith('image/') || type === 'IMAGE') {
    return 'IMAGE';
  }

  throw new Error(`Unsupported file type: ${type}`);
}

export const Route = createFileRoute(
  '/dashboard/project/$projectId/review/$reviewNumber/create',
)({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const review = project.reviews.find(
      review => review.reviewNumber === Number(params.reviewNumber),
    );

    if (!review) {
      toast("Couldn't find the Review");
      throw redirect({
        to: '/dashboard/project/$projectId',
        params: { projectId: project.id.toString() },
        throw: true,
        replace: true,
      });
    }

    return { project, review };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { review, project } = Route.useLoaderData();
  const navigate = Route.useNavigate();

  const [description, setDescription] = useState(review.description || '');
  const [assetList, setAssetList] = useState<File[]>([]);
  const [assetSourceList, setAssetSourceList] = useState<AssetGenerate[]>(
    review.assets || [],
  );

  const signFileMutation = useSignFile();
  const putFileMutation = usePutFile();
  const upsertReviewMutation = trpc.project.review.upsert.useMutation();

  const handleAssetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAssets = e.target.files;
    if (!selectedAssets) return;
    if (selectedAssets.length + assetList.length > 50) {
      toast('You can only upload a maximum of 50 files');
      return;
    }

    const newAssetSourceList: AssetGenerate[] = Array.from(selectedAssets).map(
      image => ({
        type: getType(image.type),
        url: URL.createObjectURL(image),
      }),
    );

    setAssetList([...assetList, ...selectedAssets]);
    setAssetSourceList([...assetSourceList, ...newAssetSourceList]);
  };

  const renderAsset = (asset: AssetGenerate) => {
    const assetSource = asset.url;

    switch (asset.type) {
      case 'VIDEO':
        return (
          <video
            className="h-full w-full object-cover"
            src={assetSource}
            controls
          />
        );
      case 'IMAGE':
        return (
          <img
            className="h-full w-full object-cover"
            src={assetSource}
            alt="preview of image"
          />
        );
      default:
        return null;
    }
  };

  const isMutationsPending =
    signFileMutation.isPending ||
    putFileMutation.isPending ||
    upsertReviewMutation.isPending;

  return (
    <main className="pb-48">
      <div className="flex flex-col items-center gap-20">
        <div className="flex flex-col items-center gap-10">
          <Button>
            <span className="flex flex-col items-center gap-2">
              <span>{review.reviewNumber}</span>
            </span>
          </Button>

          <Textarea
            className="min-w-[230px]"
            placeholder="Describe your review"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>

        <div className="flex flex-col items-center gap-10">
          {assetSourceList.map(asset => (
            <div
              key={asset.url}
              className={cn(
                'group relative flex aspect-video w-[90vw] max-w-[960px] cursor-pointer items-center justify-center overflow-hidden rounded-2xl ring-1 ring-gray-200',
              )}>
              {renderAsset(asset)}

              <div
                className={cn(
                  'absolute inset-0 z-10 size-auto border-none opacity-0 backdrop-blur-[2px] transition-all group-hover:opacity-100',
                  {
                    'pointer-events-none backdrop-blur-none':
                      getType(asset.type) === 'VIDEO',
                  },
                )}>
                <div className="absolute inset-0 z-0 flex items-center justify-center bg-black/10">
                  <Button
                    loading={isMutationsPending}
                    onClick={() => {
                      const filteredAssetSourceList = assetSourceList.filter(
                        prevAsset => prevAsset.url !== asset.url,
                      );

                      setAssetSourceList([...filteredAssetSourceList]);
                    }}
                    className="pointer-events-auto absolute z-10">
                    Remove
                  </Button>
                </div>
              </div>
            </div>
          ))}

          <div className="relative flex aspect-video w-[90vw] max-w-[960px] items-center justify-center overflow-hidden rounded-2xl border border-dashed border-tertiary">
            <span className="text-secondary">Upload image or video.</span>

            <input
              type="file"
              className="absolute size-full cursor-pointer opacity-0"
              onChange={handleAssetChange}
              accept="image/png,image/jpeg,video/mp4,video/webm,video/ogg,video/mov,video/quicktime"
              multiple
            />
          </div>
        </div>
      </div>

      <BottomNavigation
        left={<BackButton native />}
        middle={
          <Button
            loading={isMutationsPending}
            onClick={async () => {
              const sampleList: AssetGenerate[] = [];
              const signedWriteUrlList: string[] = [];

              for (const image of assetList) {
                const signedFile = await signFileMutation.mutateAsync({
                  name: image.name,
                  type: image.type,
                });

                if (!signedFile) return;
                sampleList.push({
                  type: getType(image.type),
                  url: signedFile.readUrl,
                });
                signedWriteUrlList.push(signedFile.writeUrl);
              }

              let index = 0;
              for (const image of assetList) {
                await putFileMutation.mutateAsync({
                  writeUrl: signedWriteUrlList[index]!,
                  file: image,
                });

                index++;
              }

              await upsertReviewMutation.mutateAsync({
                reviewId: review.id,
                description,
                id: project.id,
                assets: sampleList,
              });

              navigate({
                to: '/dashboard/project/$projectId/review/$reviewNumber/next',
                params: {
                  projectId: project.id.toString(),
                  reviewNumber: String(review.reviewNumber),
                },
              });
            }}>
            Send
          </Button>
        }
      />
    </main>
  );
}
