import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import type { Event } from 'stream-chat';
import {
  MessageNotificationProps,
  useChannelStateContext,
  useChatContext,
} from 'stream-chat-react';

export function CustomUnreadMessageNotification(
  props: MessageNotificationProps,
) {
  const { isMessageListScrolledToBottom, onClick, threadList } = props;

  const { channel: activeChannel, client } = useChatContext();
  const { thread } = useChannelStateContext();
  const [countUnread, setCountUnread] = useState(
    activeChannel?.countUnread() || 0,
  );
  const [replyCount, setReplyCount] = useState(thread?.reply_count || 0);
  const observedEvent = threadList ? 'message.updated' : 'message.new';

  useEffect(() => {
    const handleEvent = (event: Event) => {
      const newMessageInAnotherChannel = event.cid !== activeChannel?.cid;
      const newMessageIsMine = event.user?.id === client.user?.id;

      const isThreadOpen = !!thread;
      const newMessageIsReply = !!event.message?.parent_id;
      const dontIncreaseMainListCounterOnNewReply =
        isThreadOpen && !threadList && newMessageIsReply;

      if (
        isMessageListScrolledToBottom ||
        newMessageInAnotherChannel ||
        newMessageIsMine ||
        dontIncreaseMainListCounterOnNewReply
      ) {
        return;
      }

      if (event.type === 'message.new') {
        // cannot rely on channel.countUnread because active channel is automatically marked read
        setCountUnread(prev => prev + 1);
      } else if (event.message?.id === thread?.id) {
        const newReplyCount = event.message?.reply_count || 0;
        setCountUnread(() => newReplyCount - replyCount);
      }
    };
    client.on(observedEvent, handleEvent);

    return () => {
      client.off(observedEvent, handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeChannel,
    isMessageListScrolledToBottom,
    observedEvent,
    replyCount,
    thread,
  ]);

  useEffect(() => {
    if (isMessageListScrolledToBottom) {
      setCountUnread(0);
      setReplyCount(thread?.reply_count || 0);
    }
  }, [isMessageListScrolledToBottom, thread]);

  if (isMessageListScrolledToBottom) return null;

  return (
    <div className="str-chat__jump-to-latest-message">
      <button
        aria-live="polite"
        className={`str-chat__message-notification-scroll-to-latest rounded-full border border-black p-2 shadow-md`}
        data-testid="message-notification"
        onClick={onClick}>
        {/*     <ArrowDownCircle /> */}
        <ChevronDown />
        {countUnread > 0 && (
          <div
            className={clsx(
              'str-chat__message-notification',
              'str-chat__jump-to-latest-unread-count',
              'bg-black',
            )}
            data-testid={'unread-message-notification-counter'}>
            {countUnread}
          </div>
        )}
      </button>
    </div>
  );
}
