import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { motion } from 'motion/react';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CoundownTimer } from '@/components/countdown-timer';
import { ReceiptViewer } from '@/components/dialogs/founder/receipt-viewer';
import { FadeContainer } from '@/components/fade-container';
import { FoundersTag } from '@/components/founders-tag';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { OPLogo } from '@/components/op-logo';
import { SlideContainer } from '@/components/slide-container';
import { Spinner } from '@/components/spinner';
import { StatusCircle } from '@/components/status-circle';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/solid-dialog';
import { getSaveFilter } from '@/hooks/use-save-filter';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { useFounderStatusUpdate } from '@/hooks/use-websocket';
import { DAYS, IS_DEV } from '@/lib/constants';
import { capitalize, formatTimezone } from '@/lib/utils';

import { FounderStatusSchema } from '../../../../../../../backend/src/features/founder/validators';

export const Route = createFileRoute(
  '/dashboard/admin/founders/$founderId/_$founderId/',
)({
  loader: async ({ params }) => {
    await trpcClientUtils.admin.founder.get.fetch(
      {
        id: parseInt(params.founderId),
      },
      {
        gcTime: 0,
        staleTime: 0,
      },
    );
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { founderId } = Route.useParams();

    const [founder, founderQuery] = trpc.admin.founder.get.useSuspenseQuery({
      id: parseInt(founderId),
    });
    const approveFounderMutation = trpc.admin.founder.approve.useMutation();
    const confirmPaymentMutation =
      trpc.admin.founder.confirmPayment.useMutation();
    const rejectFounderMutation = trpc.admin.founder.reject.useMutation();
    const startProjectMutataion = trpc.admin.founder.startProject.useMutation();

    useFounderStatusUpdate(founder.id, () => founderQuery.refetch());
    const time = useTimeZoneClock(founder.time_zone!);

    const introCallBooking = founder.bookings.find(
      b => b.type === 'INTRO_CALL',
    );

    const [isOverrideDialogOpen, setIsOverrideDialogOpen] = useState(false);
    const [showReceiptViewer, setShowReceiptViewer] = useState(false);
    const [shouldProjectStart, setShouldProjectStart] = useState(false);

    const renderMainContent = () => {
      switch (founder.status) {
        case 'ONBOARDED':
          return (
            <Button
              loading={approveFounderMutation.isPending}
              size="lg"
              onClick={() => approveFounderMutation.mutate({ id: founder.id })}>
              Book intro call
            </Button>
          );

        case 'INTRO_CALL_REQUEST_SENT':
          return (
            <StatusCircle percentage={25}>Intro call requested</StatusCircle>
          );

        case 'INTRO_CALL_BOOKED': {
          if (!introCallBooking) return;

          const firstCall = dayjs(introCallBooking.date);
          return (
            <CoundownTimer
              startDate={dayjs(introCallBooking.created_at)}
              finishDate={firstCall}
              timeZone={founder.time_zone!}>
              First sync on {DAYS[firstCall.day()]} <br />
              {firstCall.format('DD MMM HH:mm')}
            </CoundownTimer>
          );
        }

        case 'INTRO_CALL_STARTED':
        case 'INTRO_CALL_ENDED': {
          return (
            <div className="flex flex-col items-center gap-10">
              <Button asChild size="lg">
                <Link
                  to="/dashboard/admin/founders/$founderId/proposal"
                  params={{ founderId: String(founder.id) }}>
                  Create Options
                </Link>
              </Button>

              <Button asChild>
                <a target="_blank" href="/presentation/founder">
                  Present
                </a>
              </Button>
            </div>
          );
        }

        case 'OPTIONS_SENT': {
          return (
            <Button asChild size="lg">
              <Link
                to="/dashboard/admin/founders/$founderId/proposal"
                params={{ founderId: String(founder.id) }}>
                View Options
              </Link>
            </Button>
          );
        }

        case 'OPTIONS_CONFIRMED':
        case 'INVOICE_SENT': {
          const selectedOption = founder.selectedOption!;

          return (
            <>
              <p className="mb-4">
                Founder picked {capitalize(selectedOption.type)}
              </p>
              <div className="mb-8 flex justify-center">
                <FoundersTag
                  founder={founder}
                  renderSection={['services', 'plan', 'duration']}
                />
              </div>

              <StatusCircle percentage={75} className="mx-auto">
                Waiting for
                <br />
                payment
              </StatusCircle>
            </>
          );
        }

        case 'PAYMENT_MADE': {
          const invoice = founder.invoice!;
          return (
            <div>
              <Button onClick={() => setShowReceiptViewer(true)} size="lg">
                Payment received. <br /> Click to view receipt.
              </Button>
              <ReceiptViewer
                url={invoice.receipt_url!}
                open={showReceiptViewer}
                onOpenChange={setShowReceiptViewer}
                footer={
                  <Button
                    loading={confirmPaymentMutation.isPending}
                    onClick={() => {
                      confirmPaymentMutation.mutate({ id: founder.id });
                    }}>
                    Confirm
                  </Button>
                }
              />
            </div>
          );
        }

        case 'KICKOFF_CALL_REQUEST_SENT':
          return (
            <StatusCircle percentage={90}>Kickoff call requested</StatusCircle>
          );

        case 'KICKOFF_CALL_BOOKED': {
          const kickoffBooking = founder.bookings.find(
            b => b.type === 'KICKOFF_CALL',
          );
          if (!kickoffBooking) return;
          const kickoffCallDate = dayjs(kickoffBooking.date);
          return (
            <CoundownTimer
              startDate={dayjs(kickoffBooking.created_at)}
              finishDate={kickoffCallDate}
              timeZone={founder.time_zone!}>
              Kickoff call on {DAYS[kickoffCallDate.day()]} <br />
              {kickoffCallDate.format('DD MMM HH:mm')}
            </CoundownTimer>
          );
        }

        case 'KICKOFF_CALL_STARTED':
        case 'KICKOFF_CALL_ENDED': {
          const projectStartDate = dayjs(founder.optionsPack.startDate);

          return (
            <Button
              size="lg"
              onClick={async () => {
                if (shouldProjectStart) {
                  return await startProjectMutataion.mutateAsync({
                    id: founder.id,
                  });
                }

                setShouldProjectStart(true);
              }}>
              {shouldProjectStart
                ? 'Are you sure you want to create the project?'
                : `Create Project, Project will start on ${projectStartDate.format('DD MMM HH:mm')}`}
            </Button>
          );
        }

        case 'PROJECT_STARTED': {
          const project = founder.project;
          if (!project) return null;

          return (
            <Button size="lg" asChild>
              <Link
                to="/dashboard/project/$projectId"
                params={{ projectId: project.id.toString() }}>
                View Project
              </Link>
            </Button>
          );
        }
      }
    };

    return (
      <main>
        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.99 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="mx-auto flex max-w-[480px] flex-col items-center gap-10 text-center">
          <FoundersTag
            founder={founder}
            renderSection={
              founder.selectedOption
                ? ['stage', 'services', 'plan', 'duration']
                : ['stage']
            }
          />

          <div className="flex flex-col items-center">
            <Link
              to="/dashboard/admin/founders/$founderId/info"
              params={{ founderId: String(founder.id) }}
              className="underline">
              {founder.name}
            </Link>
            <p>{founder.company_name}</p>
            <span>
              {formatTimezone(founder.time_zone || '')}{' '}
              {dayjs(time).format('HH:mm')}
            </span>
          </div>
          <SlideContainer animationKey={founder.status} className="mt-10">
            {renderMainContent()}
          </SlideContainer>
        </motion.div>
        {IS_DEV && (
          <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
            <span>
              email: {founder.user.email}
              <button
                className="ml-1 border border-dashed border-gray-600"
                onClick={() =>
                  navigator.clipboard.writeText(founder.user.email)
                }>
                copy
              </button>
            </span>
            <span>id: {founder.id}</span>
            <span>status: {founder.status}</span>

            <h3 className="mt-2">Status Table</h3>
            <hr className="h-0.5 w-full bg-gray-600" />
            <ul className="text-left">
              {FounderStatusSchema.options.map(option => (
                <li
                  key={option}
                  className={
                    option === founder.status ? 'font-bold text-black' : ''
                  }>
                  {option === founder.status && '> '} {option}
                </li>
              ))}
            </ul>
          </code>
        )}

        <BottomNavigation
          left={
            <BackButton
              to="/dashboard/admin/founders"
              search={{ filter: getSaveFilter() || 'next' }}
            />
          }
          middle={
            <Button
              size="default"
              onClick={() => setIsOverrideDialogOpen(true)}>
              Override
            </Button>
          }
        />

        <Dialog open={isOverrideDialogOpen}>
          <DialogContent>
            <div className="no-scrollbar flex max-h-screen w-[100vw] flex-col items-center overflow-y-scroll bg-white pb-40 pt-[104px] md:gap-10 md:pt-[184px]">
              <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
                <Link to="/" rel="noreferrer">
                  <OPLogo />
                </Link>
              </header>

              <p className="text-center">
                Select the step that <br /> fits the current status best.
              </p>

              <div className="mt-10 flex flex-col items-center justify-around gap-4 md:flex-row">
                <Button
                  size="lg"
                  onClick={async () => {
                    /*  await updateFounderStatusQuery.mutateAsync(
                      "introCallEnded",
                    ); */
                    history.back();
                  }}>
                  Intro Call Done
                </Button>
                <Button
                  size="lg"
                  onClick={async () => {
                    /*  await updateFounderStatusQuery.mutateAsync(
                      "projectStarted",
                    ); */
                    history.back();
                  }}>
                  Payment Done
                </Button>
                <Button
                  size="lg"
                  onClick={async () => {
                    /*   await updateFounderStatusQuery.mutateAsync("rejected"); */
                    await rejectFounderMutation.mutateAsync({ id: founder.id });
                    history.back();
                  }}>
                  Cancelled
                </Button>
                {IS_DEV && (
                  <Button
                    size="lg"
                    onClick={async () => {
                      /* await updateFounderStatusQuery.mutateAsync(
                        "projectStarted",
                      ); */
                      history.back();
                    }}>
                    Start Project
                  </Button>
                )}
              </div>
            </div>

            <section className="fixed bottom-0 left-0 right-0 z-10 flex items-center justify-center gap-2 pb-5">
              <FadeContainer>
                <Button
                  size="sm"
                  onClick={() => setIsOverrideDialogOpen(false)}>
                  <IconBackArrow />
                </Button>
              </FadeContainer>
            </section>
          </DialogContent>
        </Dialog>
      </main>
    );
  },
});
