'use client';

import { Link } from '@tanstack/react-router';

import { FeatureContainer, FeatureGroup } from '@/components/feature-groups';
import { StatusCircle } from '@/components/status-circle';
import { Designer } from '@/lib/global-types';
import { formatTimezone, generateKickOffDateLabel } from '@/lib/utils';

const StatusOrders: Designer['status'][] = [
  'ONBOARDED',

  'INTRO_CALL_REQUEST_SENT',
  'INTRO_CALL_BOOKED',
  'INTRO_CALL_STARTED',
  'INTRO_CALL_ENDED',

  'REJECTED',

  'PROJECT_ASSIGNED',
  // TODO: Add more statuses
];

export const DesignerListItem = ({ designer }: { designer: Designer }) => {
  const percentage =
    (StatusOrders.indexOf(designer.status) / (StatusOrders.length - 1)) * 100;

  return (
    <StatusCircle percentage={percentage}>
      <Link
        to="/dashboard/admin/designers/$designerId"
        preload={false}
        params={{ designerId: String(designer.id) }}
        className="flex flex-col items-center justify-center gap-6 text-center">
        {designer.is_academy_user ? (
          <div className="text-center">
            <h2>Academy User</h2>
            <h3 className="text-ellips max-h-7 overflow-hidden break-all">
              {designer.user.email}
            </h3>
          </div>
        ) : (
          <div className="text-center">
            <h2>{designer.name}</h2>
            {designer.time_zone ? (
              <h3>{formatTimezone(designer.time_zone)}</h3>
            ) : null}
          </div>
        )}
        <FeatureContainer className="scale-75">
          {designer.services.map(service => {
            return (
              <FeatureGroup key={service}>
                {service.at(0)?.toUpperCase()}
              </FeatureGroup>
            );
          })}
        </FeatureContainer>

        <div>
          <span>{generateKickOffDateLabel(designer.preferedStartDate)}</span>
          {/* // TODO: add requested rate */}
        </div>
      </Link>
    </StatusCircle>
  );
};
