import { createFileRoute, Link } from '@tanstack/react-router';
import { motion } from 'motion/react';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import DemoReel from '@/components/demo-reel';
import { FadeSection } from '@/components/fade-section';
import FeaturedBrands from '@/components/featured-brands';
import { OPLogoLarge } from '@/components/op-logo-large';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/_misc/about')({
  component: function Page() {
    return (
      <main className="container size-full w-full md:w-[50%]">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">
            <OPLogoLarge />
          </Link>
        </header>

        <motion.section className="pb-[140px] pt-[144px] text-center">
          <FadeSection>
            <Button asChild>
              <Link to="..">About</Link>
            </Button>

            <div className="mt-20">
              <FeaturedBrands />
            </div>

            <p className="mt-10">
              Founders turn to OpenPurpose® <br className="hidden md:block" />
              to transform their vision into reality.
              <br />
              <br />
              Design, Engineering and Marketing.{' '}
              <br className="hidden md:block" />
              Brand, Product, Website and Campaign.
              <br /> <br />
              We collaborate with Founders in 1 to 3 months
              <br className="hidden md:block" /> depending on their ambitions.
            </p>

            <div className="mt-20">
              <DemoReel />
            </div>

            <p className="mt-20">
              This site may be simple, but it hasn’t been easy to get here.{' '}
              <br className="hidden md:block" />
              Get to know our 15 years history and our mission below.
            </p>

            <Button
              className="my-20"
              size={'lg'}
              asChild
              onClick={() =>
                document.documentElement.scrollTo({
                  top: 0,
                  behavior: 'instant',
                })
              }>
              <Link to="/about/mission" resetScroll={true}>
                Mission
              </Link>
            </Button>

            <p>
              Our purpose is to open the borders of creative
              <br className="hidden md:block" /> opportunity. Our work is our
              identity, and how we want to <br className="hidden md:block" /> be
              seen. Our service to the ideas is how we want to be
              <br className="hidden md:block" /> remembered.
            </p>
          </FadeSection>
        </motion.section>

        <BottomNavigation className="bg-transparent" left={<BackButton />} />
      </main>
    );
  },
});
