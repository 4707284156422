import { createFileRoute, Link } from '@tanstack/react-router';
import { motion } from 'motion/react';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { LinkButton } from '@/components/link-button';
import { ProjectItem } from '@/components/project/project-item';
import { guardRoute } from '@/lib/route.utils';

export const Route = createFileRoute('/dashboard/project/')({
  beforeLoad: () => guardRoute(['ADMIN']),
  loader: async () => {
    const projects = await trpcClientUtils.project.list.fetch({
      limit: 9999999999,
      offset: 0,
    });

    return { projects };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { projects } = Route.useLoaderData();

  // TODO: add infinity scroll and pagination

  return (
    <div className="flex flex-col items-center pb-40">
      <span>{projects.length} Projects</span>

      <div className="mx-5 flex max-w-[1080px] flex-wrap justify-center gap-4 pt-20 md:mx-10">
        <motion.div
          animate={{ y: 0, opacity: 1, scale: 1 }}
          initial={{ y: 10, opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.2, delay: 0.05 }}>
          <LinkButton
            className="size-[224px]"
            to="/dashboard/admin/create-project">
            Add
          </LinkButton>
        </motion.div>
        {projects.map((project, index) => (
          <motion.div
            key={project.id}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            initial={{ y: 10, opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2, delay: (index + 1) * 0.05 }}>
            <Link
              to="/dashboard/project/$projectId"
              params={{ projectId: project.id.toString() }}
              className="">
              <ProjectItem project={project} key={project.id} />
            </Link>
          </motion.div>
        ))}
      </div>

      <BottomNavigation left={<BackButton native />} />
    </div>
  );
}
