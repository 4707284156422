import { useQuery } from '@tanstack/react-query';
import {
  Channel,
  Thread,
  ThreadStart,
  useChatContext,
} from 'stream-chat-react';

import { trpcVanilla } from '@/api/trpc';
import { IconPlus } from '@/components/icons/icon-plus';
import { Spinner } from '@/components/spinner';
import { cn, getUserTypeFromLocalStorage } from '@/lib/utils';

import { chatState$ } from '../chat.state';
import { chatClient } from '../client';
import { ChannelView } from '../components/Channel/channel-view';
import { CustomMessage } from '../components/custom-message';
import { CustomDateSeparator } from '../components/date-separator';
import { CustomUnreadMessageNotification } from '../components/unread-message-notification';

// This component is used to render the chat for a project
// two user type of user can use this chat: ADMIN and FOUNDER
export function ProjectChat() {
  const { channel: streamChannel, setActiveChannel } = useChatContext();
  const channelId = chatState$.channelId.get();

  if (!channelId) {
    throw new Error('Channel ID not provided! Did you forget to set it?');
  }

  const { email: userEmail, type } = getUserTypeFromLocalStorage();

  const channelConnectionQuery = useQuery({
    retry: 1,
    staleTime: 0,
    gcTime: 0,
    queryKey: ['channel', channelId, userEmail!, type],
    queryFn: async () => {
      const { chatToken, user } = await trpcVanilla.chat.user.query({
        email: userEmail!,
      });
      await chatClient.connectUser(user, chatToken);

      const channel = await chatClient.channel('project', channelId);

      await channel.watch();

      setActiveChannel(channel);
      return channel;
    },
  });

  if (channelConnectionQuery.isPending || !channelConnectionQuery.data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/*  <SideBar /> */}
      <Channel
        channel={streamChannel}
        ThreadStart={ThreadStart}
        DateSeparator={CustomDateSeparator}
        // @ts-expect-error - `CustomUnreadMessagesNotification` is not a valid prop
        UnreadMessagesNotification={CustomUnreadMessageNotification}
        MessageNotification={CustomUnreadMessageNotification}
        Message={CustomMessage}
        AttachmentSelectorInitiationButtonContents={() => (
          <span className="ml-4 mr-2 text-black">
            <IconPlus />
          </span>
        )}>
        <div className={cn('mx-auto flex h-[100svh] w-[500px] flex-col pb-6')}>
          <ChannelView />
        </div>

        <Thread
          additionalMessageInputProps={{
            additionalTextareaProps: {
              autoFocus: false,
              placeholder: 'Reply',
            },
          }}
        />
      </Channel>
    </>
  );
}
