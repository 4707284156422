import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { TimezoneSelector } from '@/components/timezone-selector';
import { Button } from '@/components/ui/button';
import { academyMemberCreateState$ } from '@/store/academy-member.state';

export const Route = createFileRoute('/new/academy/time-zone')({
  component: RouteComponent,
});

function RouteComponent() {
  const timeZone = academyMemberCreateState$.timeZone.get();
  const timeZoneLabel = academyMemberCreateState$.timeZoneLabel.get();
  const navigate = Route.useNavigate();

  const handleSubmit = () => {
    navigate({
      to: '/new/academy/links',
    });
  };

  return (
    <div className="flex flex-col items-center text-center">
      <h1 className="max-w-[320px] text-balance md:max-w-[400px]">
        Where are you located?
      </h1>

      <div className="mt-20 flex justify-center">
        <TimezoneSelector
          fetchOnMount
          defaultValue={timeZoneLabel}
          onChange={(timezone, city) => {
            academyMemberCreateState$.timeZone.set(timezone);
            academyMemberCreateState$.timeZoneLabel.set(city);
          }}
        />
      </div>

      <BottomNavigation
        left={<BackButton native />}
        middle={timeZone && <Button onClick={handleSubmit}>Next</Button>}
      />
    </div>
  );
}
