import { createFileRoute, Link } from '@tanstack/react-router';
import { useState } from 'react';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Links } from '@/components/links';
import { Button } from '@/components/ui/button';
import { academyMemberCreateState$ } from '@/store/academy-member.state';

export const Route = createFileRoute('/new/academy/links')({
  component: function Page() {
    const [currentLink, setCurrentLink] = useState('');
    const links = academyMemberCreateState$.links.get();

    return (
      <div className="text-center">
        <h1>
          Add at least one link. <br />
          X, IG, YT or WWW.
        </h1>

        <Links
          className="mt-10 w-screen md:mt-20 md:w-full"
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          linkList={links}
          setLinkList={links => academyMemberCreateState$.links.set(links)}
        />

        <BottomNavigation
          left={<BackButton native />}
          middle={
            links.length > 0 && (
              <Button asChild>
                <Link to="/new/academy/services">Next</Link>
              </Button>
            )
          }
        />
      </div>
    );
  },
});
