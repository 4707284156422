import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CoundownTimer } from '@/components/countdown-timer';
import { FadeContainer } from '@/components/fade-container';
import { FeatureContainer, FeatureGroup } from '@/components/feature-groups';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { OPLogo } from '@/components/op-logo';
import { SlideContainer } from '@/components/slide-container';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/solid-dialog';
import { getSaveFilter } from '@/hooks/use-save-filter';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import { useDesignerStatusUpdate } from '@/hooks/use-websocket';
import { DAYS, IS_DEV } from '@/lib/constants';
import { formatTimezone } from '@/lib/utils';

import { designerStatusSchema } from '../../../../../../backend/src/features/designer/validators';

export const Route = createFileRoute('/dashboard/admin/designers/$designerId/')(
  {
    loader: async ({ params }) => {
      const designer = await trpcClientUtils.admin.designer.get.fetch(
        {
          id: parseInt(params.designerId),
        },
        {
          gcTime: 0,
          staleTime: 0,
        },
      );

      if (designer.status === 'ONBOARDED' || designer.status === 'ONBOARDING') {
        throw redirect({
          to: `/dashboard/admin/designers/$designerId/info`,
          params,
          replace: true,
        });
      }
    },

    gcTime: 0,

    pendingComponent: () => (
      <div className="grid h-full w-full place-items-center">
        <Spinner />
      </div>
    ),
    component: function Page() {
      const { designerId } = Route.useParams();
      const [designer, designerQuery] =
        trpc.admin.designer.get.useSuspenseQuery({
          id: parseInt(designerId),
        });

      const rejectDesignerMutation = trpc.admin.designer.reject.useMutation();

      const timezoneCurrentTime = useTimeZoneClock(designer.time_zone!);

      const [isOverrideDialogOpen, setIsOverrideDialogOpen] = useState(false);

      useDesignerStatusUpdate(designer.id, () => {
        designerQuery.refetch();
      });

      const renderActionButton = () => {
        switch (designer.status) {
          case 'INTRO_CALL_REQUEST_SENT': {
            return (
              <Button disabled size={'lg'}>
                Intro call requested
              </Button>
            );
          }

          case 'INTRO_CALL_BOOKED': {
            const introCall = designer.bookings.find(
              b => b.type === 'INTRO_CALL',
            );

            if (!introCall) return null;

            return (
              <CoundownTimer
                startDate={dayjs(introCall.created_at)}
                finishDate={dayjs(introCall.date)}
                timeZone={designer.time_zone!}>
                First sync on {DAYS[dayjs(introCall.date).day()]} <br />
                {dayjs(introCall.date).format('DD MMM HH:mm')}
              </CoundownTimer>
            );
          }

          case 'INTRO_CALL_STARTED': {
            return (
              <Button size={'lg'} asChild>
                <Link target="_blank" to={`/presentation/creatives`}>
                  Present
                </Link>
              </Button>
            );
          }

          case 'INTRO_CALL_ENDED': {
            return (
              <Button size="lg" asChild>
                <Link
                  to="/dashboard/admin/designers/$designerId/assign"
                  params={{ designerId: String(designer.id) }}>
                  Assign Project
                </Link>
              </Button>
            );
          }

          case 'PROJECT_ASSIGNED': {
            const project = designer.project;

            if (!project) return null;

            return (
              <div className="flex flex-col items-center gap-5">
                <Button size="lg" asChild>
                  <Link
                    to="/dashboard/project/$projectId"
                    params={{ projectId: project.id.toString() }}>
                    Go to project
                  </Link>
                </Button>
                <Button>
                  <Link
                    to="/dashboard/admin/designers/$designerId/assign"
                    params={{ designerId: String(designer.id) }}>
                    Reassign
                  </Link>
                </Button>
              </div>
            );
          }
        }
      };

      return (
        <main className="flex flex-col items-center gap-20 pb-20 text-center">
          <div className="flex flex-col items-center gap-10">
            <FeatureContainer>
              {designer.services.map(service => {
                return (
                  <FeatureGroup key={service}>
                    {service.at(0)?.toUpperCase()}
                  </FeatureGroup>
                );
              })}
            </FeatureContainer>

            <div>
              <Link
                className="underline"
                to="/dashboard/admin/designers/$designerId/info"
                params={{ designerId: String(designer.id) }}>
                {designer.name}
              </Link>
              {designer.time_zone && (
                <h3 className="mt-2">
                  {formatTimezone(designer.time_zone)} ･{' '}
                  {timezoneCurrentTime.format('HH:mm')}
                </h3>
              )}
            </div>
          </div>

          {IS_DEV && (
            <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
              <span>
                email: {designer.user.email}
                <button
                  className="ml-1 border border-dashed border-gray-600"
                  onClick={() =>
                    navigator.clipboard.writeText(designer.user.email)
                  }>
                  copy
                </button>
              </span>
              <span>id: {designer.id}</span>
              <span>status: {designer.status}</span>

              <h3 className="mt-2">Status Table</h3>
              <hr className="h-0.5 w-full bg-gray-600" />
              <ul className="text-left">
                {designerStatusSchema.options.map(option => (
                  <li
                    key={option}
                    className={
                      option === designer.status ? 'font-bold text-black' : ''
                    }>
                    {option === designer.status && '> '} {option}
                  </li>
                ))}
              </ul>
            </code>
          )}

          <SlideContainer animationKey={designer.status}>
            {renderActionButton()}
          </SlideContainer>

          <Dialog open={isOverrideDialogOpen}>
            <DialogContent>
              <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
                <Link to="/" rel="noreferrer">
                  <OPLogo />
                </Link>
              </header>
              <div className="no-scrollbar flex max-h-screen w-[100vw] flex-col items-center overflow-y-scroll bg-white pb-40 pt-[104px] md:gap-10 md:pt-[184px]">
                <p className="text-center">
                  Select the step that <br /> fits the current status best.
                </p>

                <div className="mt-10 flex flex-col items-center justify-around gap-4 md:flex-row">
                  <Button
                    size="lg"
                    onClick={() => {
                      /*  updateDesignerStatusQuery.mutate("introCallEnded"); */
                    }}>
                    Call Done
                  </Button>
                  <Button
                    size="lg"
                    onClick={() => {
                      // TODO: add trial done status
                    }}>
                    Trial done
                  </Button>
                  <Button
                    size="lg"
                    onClick={async () => {
                      await rejectDesignerMutation.mutateAsync({
                        id: designer.id,
                      });
                      history.back();
                    }}>
                    Cancelled
                  </Button>
                </div>
              </div>

              <section className="fixed bottom-0 left-0 right-0 z-10 flex items-center justify-center gap-2 pb-5">
                <FadeContainer>
                  <Button
                    size="sm"
                    onClick={() => /*  setIsOverrideDialogOpen(false) */ {}}>
                    <IconBackArrow />
                  </Button>
                </FadeContainer>
              </section>
            </DialogContent>
          </Dialog>

          <BottomNavigation
            left={
              <BackButton
                to={
                  designer.is_academy_user
                    ? '/dashboard/admin/academy-members'
                    : '/dashboard/admin/creatives'
                }
                search={{ filter: getSaveFilter() || 'next' }}
              />
            }
            middle={
              <Button onClick={() => setIsOverrideDialogOpen(true)}>
                Override
              </Button>
            }
          />
        </main>
      );
    },
  },
);
