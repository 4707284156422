import { createFileRoute, Outlet } from '@tanstack/react-router';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { useSaveFilter } from '@/hooks/use-save-filter';

export const Route = createFileRoute('/dashboard/admin/founders/$founderId')({
  loader: async ({ params }) => {
    await trpcClientUtils.admin.founder.get.fetch(
      {
        id: parseInt(params.founderId),
      },
      {
        gcTime: 0,
        staleTime: 0,
      },
    );
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { founderId } = Route.useParams();
  const [founder] = trpc.admin.founder.get.useSuspenseQuery({
    id: parseInt(founderId),
  });

  useSaveFilter(founder);

  return <Outlet />;
}
