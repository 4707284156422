export const checkPermission = async () => {
  const prevPermission = Notification.permission;
  if (prevPermission === 'granted') return prevPermission;

  const permission = await Notification.requestPermission();
  return permission;
};

export const showNotification = async (
  title: string,
  options: NotificationOptions,
) => {
  if (Notification.permission === 'granted') {
    return new Notification(title, options);
  }
  return null;
};

export const showNewMessageNotification = async (message: string) => {
  const notification = await showNotification('You have a new message', {
    body: message,
    icon: '/favicon.ico',
  });

  notification?.addEventListener('click', () => {
    window.focus();
  });
};
