import { Prisma } from '@prisma/client';
import dayjs from 'dayjs';

export type Project = Prisma.ProjectGetPayload<{
  include: {
    updates: true;
    reviews: true;
    founder: {
      include: {
        optionsPack: {
          include: { options: true };
        };
        user: true;
        selectedOption: true;
      };
    };
    designers: {
      select: {
        user: true;
      };
    };
  };
}>;

export type Update = Prisma.UpdateGetPayload<{
  include: {
    project: true;
  };
}>;

export type Review = Prisma.ReviewGetPayload<{
  include: {
    project: true;
  };
}>;

export type { Asset } from '@prisma/client';

// This will be used all over the app to calculate the current date and time
// If you want to test the app with a different date, you can change this value
// DELETE EXTRA DATES WHEN PUSHING TO PRODUCTION!!! OR YOU WILL BE FIRED!!!
export const SYSTEM_TIME = () => dayjs();
