'use client';

import { HTMLMotionProps, motion } from 'motion/react';
import * as React from 'react';

interface FadeContainerProps extends HTMLMotionProps<'div'> {
  className?: string;
  animationKey?: string;
  duration?: number;
  delay?: number;
}

export const FadeContainer = React.forwardRef<
  HTMLDivElement,
  FadeContainerProps
>(({ children, className, animationKey, duration = 0.2, delay = 0 }, ref) => {
  return (
    <motion.div
      ref={ref}
      key={animationKey}
      layout="position"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration, delay }}
      className={className}>
      {children}
    </motion.div>
  );
});

FadeContainer.displayName = 'FadeContainer';
