import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { AssignUser } from '@/components/project/AssignUser';
import { Button } from '@/components/ui/button';
import { guardRoute } from '@/lib/route.utils';

export const Route = createFileRoute('/dashboard/project/$projectId/assign')({
  beforeLoad: () => guardRoute(['ADMIN']),
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    const designers = await trpcClientUtils.admin.designer.list.fetch({
      limit: 9999999999,
      offset: 0,
      statuses: [
        'ONBOARDED',
        'PROJECT_ASSIGNED',
        'INTRO_CALL_BOOKED',
        'INTRO_CALL_BOOKED',
        'INTRO_CALL_ENDED',
        'INTRO_CALL_REQUEST_SENT',
      ],
    });

    return { project, designers };
  },
  gcTime: 0,
  staleTime: 0,
  component: RouteComponent,
});

function RouteComponent() {
  const { project, designers } = Route.useLoaderData();
  const navigate = Route.useNavigate();
  const assignedDesigners = project.designers;

  const [selectedDesignerIndices, setSelectedDesignerIndices] = useState<
    number[]
  >(assignedDesigners.map(designer => designer.id));

  const bulkAssignProjectMutation = trpc.admin.designer.bulkAssignProject.useMutation();

  const onSubmit = async () => {
    await bulkAssignProjectMutation.mutateAsync({
      project_slug: project.slug,
      ids: selectedDesignerIndices,
    });

    navigate({
      to: '/dashboard/project/$projectId/info',
      params: { projectId: project.id.toString() },
    });
  };

  return (
    <div className="flex flex-col items-center pb-40">
      <p>Assign to Project</p>
      <div className="mx-5 flex max-w-[500px] flex-wrap justify-center gap-4 pt-20 md:mx-10">
        {designers.map(designer => (
          <AssignUser
            key={designer.id}
            designer={designer}
            isAssigned={selectedDesignerIndices.includes(designer.id)}
            onClick={() =>
              setSelectedDesignerIndices(prev => {
                if (prev.includes(designer.id)) {
                  return prev.filter(id => id !== designer.id);
                } else {
                  return [...prev, designer.id];
                }
              })
            }
          />
        ))}
      </div>

      <BottomNavigation
        left={<BackButton loading={bulkAssignProjectMutation.isPending} native />}
        middle={
          <Button loading={bulkAssignProjectMutation.isPending} onClick={onSubmit}>
            Assign
          </Button>
        }
      />
    </div>
  );
}
