import { createFileRoute, Outlet } from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { useSaveFilter } from '@/hooks/use-save-filter';

export const Route = createFileRoute('/dashboard/admin/designers/$designerId')({
  loader: async ({ params }) => {
    const designer = await trpcClientUtils.admin.designer.get.fetch({
      id: parseInt(params.designerId),
    });

    return {
      designer,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { designer } = Route.useLoaderData();
  useSaveFilter(designer);

  return <Outlet />;
}
