import { MessageInput, MessageList } from 'stream-chat-react';

export function ChannelView() {
  return (
    <>
      <nav className="border-b border-gray-200">
        {/*   <Popover
          open={addMemberDialogOpen}
          onOpenChange={setAddMemberDialogOpen}>
          <PopoverTrigger asChild>
            <UserRoundPlus className="cursor-pointer" />
          </PopoverTrigger>

          <PopoverContent className="p-2">
            <Command>
              <CommandInput />
              <CommandList className="mt-2">
                <CommandEmpty>No user found</CommandEmpty>
                {admins.map(admin => (
                  <CommandItem
                    className="cursor-pointer data-[selected=true]:bg-black data-[selected=true]:text-white"
                    key={admin.id}
                    value={admin.id}
                    onSelect={() => {
                      setSelectedAdmins(prev =>
                        prev.includes(admin.id)
                          ? prev.filter(id => id !== admin.id)
                          : [...prev, admin.id],
                      );
                    }}>
                    {admin.name || admin.id}

                    {selectedAdmins.includes(admin.id) && <Check />}
                  </CommandItem>
                ))}
              </CommandList>

              <CommandSeparator className="h-2" />

              {selectedAdmins.length > 0 && (
                <ShadButton
                  className="rounded-full text-white"
                  onClick={handleAddChannelMembers}>
                  Add {selectedAdmins.length} users
                </ShadButton>
              )}
            </Command>
          </PopoverContent>
        </Popover> */}
      </nav>
      <MessageList />
      <MessageInput
        grow
        additionalTextareaProps={{ placeholder: 'Message #openpurpose' }}
      />
    </>
  );
}
