import dayjs from 'dayjs';
import {
  Attachment,
  Avatar,
  EditMessageForm,
  MessageDeleted,
  MessageInput,
  MessageText,
  Modal,
  useChannelStateContext,
  useMessageContext,
} from 'stream-chat-react';

import { IconClose } from '@/components/icons/icon-close';
import { IconEdit } from '@/components/icons/icon-edit';
import { IconPlus } from '@/components/icons/icon-plus';
import { cn } from '@/lib/utils';

const customReactions = [
  {
    type: 'like',
    emoji: '🫡',
    using: true,
  },
  {
    type: 'clap',
    emoji: '🙌',
    using: false,
  },
  {
    type: 'check',
    emoji: '✅',
    using: false,
  },
  {
    type: 'love',
    emoji: '🤍',
    using: true,
  },
  {
    type: 'haha',
    emoji: '😂',
    using: false,
  },
  {
    type: 'sad',
    emoji: '😢',
    using: false,
  },
  {
    type: 'wow',
    emoji: '😲',
    using: false,
  },
];

export const CustomMessage = () => {
  const {
    isMyMessage,
    message,
    groupStyles,
    handleOpenThread,
    handleDelete,
    editing,
    clearEditingState,
    additionalMessageInputProps,
    handleEdit,
  } = useMessageContext();

  const { channel } = useChannelStateContext();

  const user = message.user;

  const hasReaction = Object.keys(message.reaction_counts || {}).length > 0;

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />;
  }

  return (
    <div
      className={cn(
        'op-chat-ui-messages',
        groupStyles?.map(style => `op-chat-ui-messages--${style}`).join(' '),
        {
          'op-chat-ui-messages--other': !isMyMessage(),
          'op-chat-ui-messages--mine': isMyMessage(),
        },
      )}
      data-message-id={message.id}>
      <span className={cn('op-chat-ui-messages__name')}>
        <Avatar image={user?.image} name={user?.name} />
        <span>
          {user?.name} at {dayjs(message.created_at).format('HH:mm')}
        </span>
      </span>
      <div
        className={cn('op-chat-ui-messages__message', {
          'op-chat-ui-messages__message--has-reaction': hasReaction,
        })}>
        <MessageText />
        {message.attachments && (
          <Attachment attachments={message.attachments} />
        )}
        {/* Actions */}
        <div
          className={cn(
            'op-chat-ui-messages__actions',
            groupStyles
              ?.map(style => `op-chat-ui-messages__actions--${style}`)
              .join(' '),
          )}>
          {!isMyMessage() &&
            customReactions
              .filter(r => r.using)
              .map((reaction, index) => (
                <button
                  key={index}
                  onClick={() => {
                    channel.sendReaction(message.id, {
                      type: reaction.type,
                    });
                  }}>
                  {reaction.emoji}
                </button>
              ))}

          <button className="h-6" onClick={handleOpenThread}>
            <IconPlus />
          </button>

          {isMyMessage() && (
            <>
              <button className="h-6" onClick={handleEdit}>
                <IconEdit />
              </button>
              <button className="h-6" onClick={handleDelete}>
                <IconClose />
              </button>
            </>
          )}
        </div>

        {/* Reactions */}
        {hasReaction && (
          <div className={cn('op-chat-ui-messages__reactions')}>
            {Object.entries(message.reaction_counts || {}).map(
              ([reactionType, count]) => {
                const reaction = customReactions.find(
                  r => r.type === reactionType,
                );

                const hasUserReacted = message.own_reactions?.some(
                  r => r.type === reactionType,
                );

                return (
                  <button
                    className={cn('op-chat-ui-messages__reactions__reaction', {
                      'op-chat-ui-messages__reactions__reaction--active':
                        hasUserReacted,
                    })}
                    onClick={() => {
                      if (hasUserReacted) {
                        channel.deleteReaction(message.id, reactionType);
                      } else {
                        channel.sendReaction(message.id, {
                          type: reactionType,
                        });
                      }
                    }}
                    key={reactionType}>
                    {reaction?.emoji} {count}
                  </button>
                );
              },
            )}
          </div>
        )}
      </div>
      {/* Replies */}
      {(message.reply_count || 0) > 0 && (
        <button
          className={cn('op-chat-ui-messages__replies')}
          onClick={handleOpenThread}>
          {message.reply_count} Replies
        </button>
      )}

      {/* Editing */}
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            hideSendButton
            Input={EditMessageForm}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
    </div>
  );
};
