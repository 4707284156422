import { createFileRoute, Link } from '@tanstack/react-router';

import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/new/academy/thank-you')({
  component: function Page() {
    return (
      <div className="text-center">
        <h1 className="mt-10 md:mt-20">
          Thank you
          <br />
          for joining.
          <br /> <br />
          We will share the final list
          <br />
          of our students by April 7th.
          <br />
          <br />
          In the meantime follow <br />
          our{' '}
          <a
            href="https://instagram.com/openpurpose"
            target="_blank"
            className="underline"
            rel="noreferrer">
            Instagram
          </a>{' '}
          for updates.
          <br />
          See you soon.
        </h1>

        <BottomNavigation
          left={
            <Button asChild>
              <Link to="/">Close</Link>
            </Button>
          }
        />
      </div>
    );
  },
});
