'use client';

import { HTMLMotionProps, motion } from 'motion/react';
import * as React from 'react';

export interface SlideContainerProps extends HTMLMotionProps<'div'> {
  className?: string;
  animationKey?: string;
  direction?: 'forwards' | 'backwards';
  duration?: number;
  delay?: number;
}

export const SlideContainer = React.forwardRef<
  HTMLDivElement,
  SlideContainerProps
>(
  (
    {
      children,
      className,
      animationKey,
      direction = 'forwards',
      duration = 0.2,
      delay = 0,
    },
    ref,
  ) => {
    const isForwards = direction === 'forwards';

    return (
      <motion.div
        ref={ref}
        key={animationKey}
        initial={{
          translateX: isForwards ? '2.5rem' : '-2.5rem',
          opacity: 0,
        }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{
          translateX: isForwards ? '-2.5rem' : '2.5rem',
          opacity: 0,
        }}
        transition={{ duration, delay }}
        className={className}
        style={{ willChange: 'auto', overflowX: 'hidden' }}>
        {children}
      </motion.div>
    );
  },
);

SlideContainer.displayName = 'SlideContainer';
