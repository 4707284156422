import '@/lib/setup-pdf';

import {
  createFileRoute,
  Link,
  Outlet,
  redirect,
} from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { ChatDialog } from '@/features/chat/views/chat-dialog';
import { TRPCRouterOutput } from '@/lib/global-types';
import { getUserTypeFromLocalStorage } from '@/lib/utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute('/dashboard')({
  beforeLoad: ({ location }) => {
    // this will run only dashboard/ route
    if (!location.pathname.match(/\/dashboard\/?$/)) return;

    const cookieStore = window.localStorage;

    const userType = cookieStore.getItem('userType') as
      | TRPCRouterOutput['auth']['loginSSO']['user_type']
      | undefined;

    if (!userType) {
      toast('You are not authorized to access this page.');
      return redirect({ to: '/login' });
    }

    switch (userType) {
      case 'ADMIN':
        return redirect({
          to: '/dashboard/admin',
          search: { filter: undefined },
        });
      case 'FOUNDER':
        return redirect({ to: '/dashboard/founder' });
      case 'DESIGNER':
        return redirect({ to: '/dashboard/designer' });

      default:
        throw new Error(`Unknown user type: ${userType} or not implemented`);
    }

    /*    case "designer":
        return redirect({ to: "/dashboard/designer" });

      case "engineer":
        throw new Error("Not implemented"); */
  },

  loader: async () => {
    const user = await trpcClientUtils.user.get.fetch();

    return {
      user,
    };
  },

  component: function Layout() {
    const { user } = Route.useLoaderData();
    const { isDesigner } = getUserTypeFromLocalStorage();

    return (
      <>
        {/* Show profile image only for designer */}
        {isDesigner && (
          <Link
            className="fixed right-4 top-4 z-30 h-12 w-12 cursor-pointer"
            to={'/dashboard/designer/settings'}>
            <img
              className="h-full w-full rounded-full border border-[#00000014]"
              src={user.image || '/blank-profile.jpeg'}
              alt={`${user.name} avatar`}
            />
          </Link>
        )}
        <Outlet />
        <ChatDialog />
      </>
    );
  },
});
