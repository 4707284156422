import { useQuery } from '@tanstack/react-query';

import { trpc } from '@/api/trpc';
import { getUserTypeFromLocalStorage } from '@/lib/utils';

import { chatState$ } from './chat.state';
import { chatClient } from './client';

// Call it once
export function useChatConnection() {
  const { email: userEmail, type } = getUserTypeFromLocalStorage();

  const userQuery = trpc.chat.user.useQuery(
    { email: userEmail! },
    { enabled: type === 'ADMIN' },
  );
  const userData = userQuery.data;

  const chatConnectionQuery = useQuery({
    enabled: !!userData,
    staleTime: 0,
    queryKey: ['chat_user', userEmail!, userData],
    queryFn: async () => {
      if (!userData) return false;

      const { user, chatToken } = userData;

      await chatClient.connectUser(user, chatToken);

      const unreadCount = await chatClient.getUnreadCount();
      chatState$.unreadCount.set(unreadCount.total_unread_count);
      return true;
    },
  });

  return chatConnectionQuery;
}
