import { createFileRoute, Link } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { ServiceType } from '@/lib/global-types';
import { academyMemberCreateState$ } from '@/store/academy-member.state';

export const Route = createFileRoute('/new/academy/services')({
  component: function Page() {
    const services = academyMemberCreateState$.services.get();

    return (
      <div className="text-center">
        <h1>
          {'What do you do best?'} <br />
          {'You can select more than one.'}
        </h1>

        <div className="mt-10 md:mt-20">
          <ToggleGroup
            type="multiple"
            value={services}
            onValueChange={(value: ServiceType[]) => {
              academyMemberCreateState$.services.set(value);
            }}>
            <ToggleGroupItem value="BRAND">Brand</ToggleGroupItem>
            <ToggleGroupItem value="PRODUCT">Product</ToggleGroupItem>
            <ToggleGroupItem value="WEBSITE">Website</ToggleGroupItem>
          </ToggleGroup>
        </div>

        <BottomNavigation
          middle={
            services.length > 0 && (
              <Button asChild>
                <Link to={'/new/academy/samples'}>Next</Link>
              </Button>
            )
          }
          left={<BackButton native />}
        />
      </div>
    );
  },
});
