import { getProjectAmountFromOption } from '@op-platform/backend/founder/utils';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { OfferButton } from '@/components/proposals/offer-button';
import { OfferDialog } from '@/components/proposals/offer-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Option, TRPCRouterInput } from '@/lib/global-types';

export const Route = createFileRoute('/dashboard/admin/create-invoice')({
  component: RouteComponent,
});

const option: Option = {
  id: 99999999,
  optionPackId: 99999999,
  description: '',
  plan: null,
  services: [],
  type: 'REQUESTED',
};

function RouteComponent() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [payload, setPayload] = useState({
    company_address: '',
    company_name: '',
    country: '',
    legal_name: '',
    amount: 0,
    option,
    vat_number: '',
  } satisfies TRPCRouterInput['admin']['createInvoice']);

  const createInvoiceMutation = trpc.admin.createInvoice.useMutation();
  const latestInvoiceNumberQuery = trpc.admin.getLatestInvoiceNumber.useQuery();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const invoice = await createInvoiceMutation.mutateAsync(payload);
    window.open(invoice.url, '_blank');
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col items-center gap-10 pb-40">
        <h1>
          Dont forget, Invoice number will be incremented by 1 <br /> Latest
          invoice number #
          {(latestInvoiceNumberQuery.data || 0).toString().padStart(5, '0')}
        </h1>
        <Input
          placeholder="Company Name"
          value={payload.company_name}
          onChange={e =>
            setPayload({ ...payload, company_name: e.target.value })
          }
        />

        <Input
          placeholder="Company Address"
          value={payload.company_address}
          onChange={e =>
            setPayload({ ...payload, company_address: e.target.value })
          }
        />

        <Input
          placeholder="Legal Name"
          value={payload.legal_name}
          onChange={e => setPayload({ ...payload, legal_name: e.target.value })}
        />

        <Input
          placeholder="Country"
          value={payload.country}
          onChange={e => setPayload({ ...payload, country: e.target.value })}
        />

        <Input
          placeholder="VAT Number"
          value={payload.vat_number}
          onChange={e => setPayload({ ...payload, vat_number: e.target.value })}
        />

        <OfferButton
          title="Serives & Plans"
          payload={payload.option}
          onClick={() => setIsDialogOpen(true)}
        />

        <OfferDialog
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            /* saveMutation.mutate(true); */
          }}
          option={payload.option}
          setOption={newOption => {
            if (newOption) {
              setPayload({
                ...payload,
                option: newOption,
                amount: getProjectAmountFromOption(newOption),
              });
            }
          }}
        />

        <div>
          <p>Amount</p>

          <Input
            className="mt-2"
            placeholder="Amount"
            type="number"
            value={payload.amount}
            onChange={e => setPayload({ ...payload, amount: +e.target.value })}
          />
        </div>
      </div>

      <BottomNavigation
        left={<BackButton />}
        middle={
          <Button loading={createInvoiceMutation.isPending} type="submit">
            Create Invoice
          </Button>
        }
      />
    </form>
  );
}
