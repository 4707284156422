import { useEffect } from 'react';

import { Designer, Founder } from '@/lib/global-types';
import { Filter } from '@/routes/dashboard/admin/_admin.index';
import { CREATIVE_FILTERS } from '@/routes/dashboard/admin/creatives';
import { FOUNDER_FILTERS } from '@/routes/dashboard/admin/founders/_founders.index';

export const LOCAL_STORAGE_FILTER_KEY = 'filter';

/**
 * this hook will save the dashboard filter in the local storage,
 * use in founder and designers pages
 * it allows to determine the filter to be used when the user navigates to the dashboard
 * @param user
 */
export function useSaveFilter(user: Founder | Designer) {
  useEffect(() => {
    switch (user.user.user_type) {
      case 'FOUNDER': {
        const filter = Object.keys(FOUNDER_FILTERS).find(key =>
          FOUNDER_FILTERS[key as keyof typeof FOUNDER_FILTERS].includes(
            user.status as Founder['status'],
          ),
        ) as keyof typeof FOUNDER_FILTERS;
        localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, filter);
        break;
      }
      case 'DESIGNER': {
        const filter = Object.keys(CREATIVE_FILTERS).find(key =>
          CREATIVE_FILTERS[key as keyof typeof CREATIVE_FILTERS].includes(
            user.status as Designer['status'],
          ),
        ) as keyof typeof CREATIVE_FILTERS;
        localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, filter);
        break;
      }

      default:
        break;
    }
  }, [user]);
}

export function getSaveFilter() {
  return localStorage.getItem(LOCAL_STORAGE_FILTER_KEY) as Filter | null;
}
