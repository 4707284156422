import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  Avatar,
  Channel,
  Thread,
  useChannelStateContext,
  useChatContext,
  useTranslationContext,
} from 'stream-chat-react';

import { trpc } from '@/api/trpc';
import { IconPlus } from '@/components/icons/icon-plus';
import { Spinner } from '@/components/spinner';
import { cn, getUserTypeFromLocalStorage } from '@/lib/utils';

import { chatClient } from '../client';
import { ChannelView } from '../components/Channel/channel-view';
import { CustomMessage } from '../components/custom-message';
import { CustomDateSeparator } from '../components/date-separator';
import { CustomUnreadMessageNotification } from '../components/unread-message-notification';

export const ThreadStart = () => {
  const { thread } = useChannelStateContext('ThreadStart');
  const { t } = useTranslationContext('ThreadStart');

  if (!thread?.reply_count) return null;

  const participants = thread.thread_participants || [];

  return (
    <div className="str-chat__thread-start mb-10 flex items-center justify-center gap-2">
      <div className="flex flex-row flex-wrap gap-2">
        {participants.map(participant => (
          <Avatar
            key={participant.id}
            image={participant.image}
            name={participant.name}
          />
        ))}
      </div>
      {t<string>('replyCount', { count: thread.reply_count })}
    </div>
  );
};

export function AdminChat() {
  const { channel: streamChannel, setActiveChannel } = useChatContext();
  const { email: userEmail } = getUserTypeFromLocalStorage();

  const channelIdQuery = trpc.chat.adminChannelId.useQuery();
  const channelId = channelIdQuery.data;

  const channelConnectionQuery = useQuery({
    retry: 1,
    staleTime: 0,
    gcTime: 0,
    enabled: !!channelId,
    queryKey: ['channel', channelId, userEmail!],
    queryFn: async () => {
      if (!channelId) return;

      const channel = chatClient.channel('team', channelId);

      await channel.watch();

      setActiveChannel(channel);
      return channel;
    },
  });

  useEffect(() => {
    return () => {
      streamChannel?.stopWatching();
    };
  }, [streamChannel]);

  if (channelConnectionQuery.isPending || !channelConnectionQuery.data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/*  <SideBar /> */}
      <Channel
        channel={streamChannel}
        ThreadStart={ThreadStart}
        DateSeparator={CustomDateSeparator}
        // @ts-expect-error - `CustomUnreadMessagesNotification` is not a valid prop
        UnreadMessagesNotification={CustomUnreadMessageNotification}
        MessageNotification={CustomUnreadMessageNotification}
        Message={CustomMessage}
        AttachmentSelectorInitiationButtonContents={() => (
          <span className="ml-4 mr-2 text-black">
            <IconPlus />
          </span>
        )}>
        <div className={cn('mx-auto flex h-[100svh] w-[500px] flex-col pb-6')}>
          <ChannelView />
        </div>

        <Thread
          additionalMessageInputProps={{
            additionalTextareaProps: {
              autoFocus: false,
              placeholder: 'Reply',
            },
          }}
        />
      </Channel>
    </>
  );
}
