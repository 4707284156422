import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { motion } from 'motion/react';
import { useState } from 'react';

import { trpc } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import { useIsMobile } from '@/hooks/use-is-mobile';
import { Designer } from '@/lib/global-types';
import {
  creativeOnboardingState$,
  isCreativeOnboardingCompleted$,
} from '@/store/creatives.state';

export const Route = createFileRoute('/onboarding/creatives/$type/levels')({
  beforeLoad: ({ params }) => {
    if (!isCreativeOnboardingCompleted$.peek()) {
      return redirect({ to: '/onboarding/creatives/$type', params });
    }
  },
  component: function Page() {
    const navigate = Route.useNavigate();
    const { isMobile } = useIsMobile();
    const [showDetails, setShowDetails] = useState(true);

    const onboardDesignerMutation = trpc.designer.onboard.useMutation();

    const onClickLevel = async (level: Designer['level']) => {
      creativeOnboardingState$.level.set(level);

      const designer = creativeOnboardingState$.peek();
      await onboardDesignerMutation.mutateAsync({
        name: designer.name,
        time_zone: designer.time_zone,
        level: designer.level!,
      });
      navigate({ to: '/dashboard/designer' });
    };
    return (
      <div className="text-center">
        <h1>
          We work Mon to Thu, ~9am until 5pm. <br />
          OP projects are 1 to 3 months long. <br />
          Which role fits you best?
        </h1>

        <button
          className="mt-2 underline"
          onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? 'Hide' : 'View'} details
        </button>

        <div className="mx-auto mt-10 w-screen md:mt-20 md:w-fit">
          <Carousel
            opts={{
              containScroll: isMobile ? false : 'keepSnaps',
              active: isMobile,
            }}>
            <CarouselContent>
              <CarouselItem
                className="flex basis-[80%] items-center justify-center md:basis-1/3"
                onClick={() => onClickLevel('ASSOCIATE')}>
                <Button
                  loading={onboardDesignerMutation.isPending}
                  className="flex flex-col gap-6"
                  size={'xl'}>
                  <motion.p layout layoutDependency={showDetails}>
                    Associate
                  </motion.p>
                  {showDetails && (
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                      Collaborate with leads to <br />
                      evolve your skills.
                    </motion.p>
                  )}
                  <motion.p layout layoutDependency={showDetails}>
                    $2,000 - $4,000
                  </motion.p>
                </Button>
              </CarouselItem>
              <CarouselItem
                className="flex basis-[80%] items-center justify-center md:basis-1/3"
                onClick={() => onClickLevel('LEAD')}>
                <Button
                  loading={onboardDesignerMutation.isPending}
                  className="flex flex-col gap-6"
                  size={'xl'}>
                  <motion.p layout layoutDependency={showDetails}>
                    Lead
                  </motion.p>
                  {showDetails && (
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                      Collaborate with OP <br />
                      on Founders projects.
                    </motion.p>
                  )}
                  <motion.p layout layoutDependency={showDetails}>
                    $6,000 - $8,000
                  </motion.p>
                </Button>
              </CarouselItem>
              <CarouselItem
                className="flex basis-[80%] items-center justify-center md:basis-1/3"
                onClick={() => onClickLevel('DIRECTOR')}>
                <Button
                  loading={onboardDesignerMutation.isPending}
                  className="flex flex-col gap-6"
                  size={'xl'}>
                  <motion.p layout layoutDependency={showDetails}>
                    Director
                  </motion.p>
                  {showDetails && (
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                      Collaborate with Founders <br /> on projects while
                      coaching leads.
                    </motion.p>
                  )}
                  <motion.p layout layoutDependency={showDetails}>
                    $10,000 - $12,000
                  </motion.p>
                </Button>
              </CarouselItem>
            </CarouselContent>
          </Carousel>
        </div>

        <BottomNavigation
          middle={
            <Button
              disabled={onboardDesignerMutation.isPending}
              size={'sm'}
              asChild>
              <Link to="..">
                <IconBackArrow />
              </Link>
            </Button>
          }
        />
      </div>
    );
  },
});
