import { createFileRoute, Link } from '@tanstack/react-router';
import { useEffect } from 'react';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { FadeSection } from '@/components/fade-section';
import { OPLogoLarge } from '@/components/op-logo-large';

export const Route = createFileRoute('/_misc/academy_/story')({
  component: RouteComponent,
});

function RouteComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="flex min-h-screen flex-col items-center justify-center">
      <header className="fixed left-1/2 top-6 z-20 flex -translate-x-1/2 justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogoLarge />
        </Link>
      </header>

      <FadeSection>
        <div className="mx-auto my-[104px] mb-[96px] flex max-w-lg flex-col gap-6 text-balance px-4">
          <div className="relative mb-4 flex aspect-video w-full items-end justify-center rounded-2xl border border-black/[0.08] p-4">
            <video
              src="/op-flag.mp4"
              autoPlay
              loop
              muted
              playsInline
              className="absolute inset-0 size-full select-none object-contain"
            />
          </div>

          <p className="max-w-md">
            OP® Academy is our second step towards bringing opportunities at
            scale to creatives. 15 years ago, I started OP® Services from a
            very small room in a refugee camp with the belief that this work
            would become the key to my freedom.
          </p>

          <p className="max-w-md">
            Through the collaborations over the past 1.5 decades, I quietly
            built a network of entrepreneurial creatives at many different
            stages of their journey—from tech unicorn founders to avant-garde
            brand owners.
          </p>
          <p className="max-w-md">
            Unique individuals with unstoppable passion to follow their purpose
            and build what’s on their mind. The opportunity creators that drive
            the world’s creative economy.
          </p>

          <p className="max-w-md">
            Through this journey, we’ve built a protocol for creatives to launch
            their ideas. And it starts with coaching designers to own the entire
            creative spectrum of creative venture building—from Brand and
            Marketing to Product design. Next, we will begin our class for
            engineers to bridge the gap to design and become design engineers.
            And at last, we will teach you how to become a creative founder.
          </p>

          <p className="max-w-md">
            Our third step, on our mission to bring opportunities to creatives
            at scale, will be OP® Ventures. In partnership with governments and
            investors, we will launch a new path to fund and launch your
            company.
          </p>

          <p className="max-w-md">
            OpenPurpose® is for you. I got my freedom. Now I’m multiplying the
            key to your freedom at scale so more can walk through the door and
            own their future.
          </p>

          <p className="max-w-md">
            And we’ve made it very simple for you. All you need to do is to
            start and OP® will be along side you at every step of the way.
          </p>
          <p className="flex flex-col items-center justify-center">
            <a
              href="https://x.com/blankresident"
              className="underline"
              target="_blank"
              rel="noreferrer">
              <img
                src="/blank-signature.png"
                alt="signature"
                className="mb-4 h-auto w-[120px]"
              />
              blank
            </a>
            <span>Founder</span>
          </p>
        </div>
      </FadeSection>

      <BottomNavigation className="" left={<BackButton native />} />
    </main>
  );
}
