export const IconEdit = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2038 1.98599C10.4717 1.70136 10.9133 1.67019 11.2185 1.91436L13.7185 3.91436C13.8791 4.04284 13.9799 4.23163 13.9973 4.43656C14.0147 4.64149 13.9472 4.84457 13.8106 4.99828L5.81055 13.9982C5.66823 14.1583 5.46423 14.2499 5.25 14.2499H2.75C2.33579 14.2499 2 13.9141 2 13.4999V11C2 10.809 2.07291 10.6251 2.20385 10.486L10.2038 1.98599ZM10.8219 3.51797L3.5 11.2974V12.7499H4.9132L12.1646 4.59217L10.8219 3.51797Z"
        fill="currentColor"
      />
    </svg>
  );
};
