import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { academyMemberCreateState$ } from '@/store/academy-member.state';

export const Route = createFileRoute('/new/academy/email')({
  component: function Page() {
    const email = academyMemberCreateState$.email.get();
    const navigate = Route.useNavigate();

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          navigate({ to: '/new/academy/time-zone' });
        }}>
        <section className="flex flex-col items-center justify-center text-center">
          <h1>What’s your email?</h1>

          <Input
            className="mt-10 md:mt-20"
            autoFocus
            name="email"
            id="email"
            type={'email'}
            onChange={e => {
              academyMemberCreateState$.email.set(e.target.value);
            }}
            placeholder={'@'}
            value={email}
            size={1}
          />
        </section>

        <BottomNavigation
          middle={email && <Button type="submit">Next</Button>}
          left={<BackButton native />}
        />
      </form>
    );
  },
});
