import { useEffect } from 'react';

import { chatState$ } from './chat.state';
import { chatClient } from './client';
import { checkPermission, showNewMessageNotification } from './notification';

export function useUnread() {
  useEffect(() => {
    checkPermission();
    const subs = chatClient.on(async event => {
      switch (event.type) {
        case 'notification.message_new': {
          chatState$.unreadCount.set(event.unread_count || 0);

          const messageText = event.message?.text || 'N/A';
          const from = event.message?.user?.name || 'Someone';

          await showNewMessageNotification(`${from} says: ${messageText}`);
          break;
        }
      }
    });

    return () => {
      subs.unsubscribe();
    };
  }, []);
}
