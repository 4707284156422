import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { academyMemberCreateState$ } from '@/store/academy-member.state';

export const Route = createFileRoute('/new/academy/')({
  component: RouteComponent,
});

function RouteComponent() {
  const name = academyMemberCreateState$.name.get();
  const navigate = Route.useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    navigate({
      to: '/new/academy/email',
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center text-center">
      <h1 className="max-w-[320px] text-balance md:max-w-[400px]">
        What’s your name?
      </h1>

      <div className="mt-20 flex justify-center">
        <Input
          autoFocus
          type={'text'}
          onChange={e => {
            academyMemberCreateState$.name.set(e.target.value);
          }}
          placeholder={'Your name'}
          value={name}
          size={1}
        />
      </div>

      <BottomNavigation
        left={<BackButton native />}
        middle={name && <Button type="submit">Next</Button>}
      />
    </form>
  );
}
