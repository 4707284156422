import { useMutation } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useRef, useState } from 'react';

import { usePutFile, useSignFile } from '@/api/services/upload.service';
import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { FadeSection } from '@/components/fade-section';
import { LogoutButton } from '@/components/icons/icon-logout';
import { OPLogo } from '@/components/op-logo';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { chatClient } from '@/features/chat/client';
import { guardRoute } from '@/lib/route.utils';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute('/dashboard/settings')({
  beforeLoad: () => guardRoute(['ADMIN']),
  loader: async () => {
    const user = trpcClientUtils.user.get.fetch();

    return {
      user,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const [user] = trpc.user.get.useSuspenseQuery();
  const updateMutation = trpc.user.update.useMutation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [profileImageUrl, setProfileImageUrl] = useState(user.image);
  const [_name, _setName] = useState(user.name || '');
  const navigate = Route.useNavigate();
  const chatUser = trpc.chat.user.useQuery({ email: user.email });

  const signFileMutation = useSignFile();
  const putFileMutation = usePutFile();

  const prefetchImageMutation = useMutation({
    mutationFn: async (url: string) => {
      const img = new Image();
      img.src = url;
      await new Promise(resolve => {
        img.onload = resolve;
      });
    },
  });

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const signedFile = await signFileMutation.mutateAsync({
      name: file.name,
      type: file.type,
    });

    await putFileMutation.mutateAsync({
      file: file,
      writeUrl: signedFile.writeUrl,
    });

    // also prefetch the image and wait for it to load
    prefetchImageMutation.mutate(signedFile.readUrl);

    setProfileImageUrl(signedFile.readUrl);
  };

  const profileImageLoading =
    signFileMutation.isPending ||
    putFileMutation.isPending ||
    prefetchImageMutation.isPending;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
    };

    const name = target.name.value;

    await updateMutation.mutateAsync({ name, image: profileImageUrl });

    if (chatUser.data) {
      chatClient.upsertUser({
        id: chatUser.data.user.id,
        name,
        image: profileImageUrl || undefined,
      });
    }

    toast('Profile updated');
  };

  return (
    <div className="h-svh">
      <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogo />
        </Link>
      </header>

      <form
        className="flex h-full flex-col items-center gap-10 pb-40 pt-36"
        onSubmit={handleSubmit}>
        <FadeSection>
          <button
            type="button"
            className="relative h-28 w-28 overflow-hidden rounded-full"
            onClick={() => {
              inputRef.current?.click();
            }}>
            <img
              className="h-full w-full rounded-full border border-[#00000014]"
              src={profileImageUrl || '/blank-profile.jpeg'}
              alt={`${user.name} avatar`}
            />
            {profileImageLoading && (
              <div className="absolute inset-0 flex h-full w-full justify-center bg-black/40">
                <Spinner />
              </div>
            )}
            <input
              type="file"
              hidden
              ref={inputRef}
              onChange={handleImageChange}
              accept="image/*"
            />
          </button>

          <Input
            value={_name}
            onChange={e => {
              _setName(e.target.value);
            }}
            placeholder="Name"
            id="name"
          />

          <Button asChild>
            <Link to="/dashboard/admin/create-invoice">Create Invoice</Link>
          </Button>
        </FadeSection>

        <BottomNavigation
          left={
            <BackButton
              type="button"
              disabled={updateMutation.isPending}
              native
            />
          }
          middle={
            <Button loading={updateMutation.isPending} type="submit">
              Save
            </Button>
          }
          right={
            <Button
              type="button"
              size={'sm'}
              onClick={() => {
                localStorage.clear();
                navigate({ to: '/login' });
              }}>
              <LogoutButton />
            </Button>
          }
        />
      </form>
    </div>
  );
}
