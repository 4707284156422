import { createFileRoute, Link } from '@tanstack/react-router';
import { useEffect } from 'react';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { FadeSection } from '@/components/fade-section';
import { OPLogo } from '@/components/op-logo';
import { OPLogoLarge } from '@/components/op-logo-large';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/_misc/academy')({
  component: RouteComponent,
});

function RouteComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="flex min-h-screen flex-col items-center justify-center">
      <header className="fixed left-1/2 top-6 z-20 flex -translate-x-1/2 justify-center text-white mix-blend-difference md:top-10">
        <Link to="/">
          <OPLogoLarge />
        </Link>
      </header>

      <div className="mx-auto mb-[176px] mt-[104px] flex w-full max-w-lg flex-col gap-6 text-balance px-4">
        <FadeSection>
          <div className="mb-4 flex flex-col items-center">
            <span>Last day to enroll to</span>
            <div className="flex items-center justify-center gap-2">
              <OPLogo /> <span>Academy</span>
            </div>
          </div>
          <p className="mx-auto w-full max-w-md">
            Learn to become a founders most
            <br />
            valuable partner to bring their vision to life. <br />
            Products, Brands & Websites in 3 weeks.
            <br />
            <br />
            Each week, we focus on one discipline:
            <br />
            We begin with Product, then Brand
            <br />
            and Website.
            <br />
            <br />
            We believe in learning by doing.
            <br />
            Monday to Thursday, 4 hours a day.
            <br />
            All async.
            <br />
            <br />
            Enrollment closes March 31st.
            <br />
            Class starts April 14th.
            <br />
            <br />
            This course is not for beginners.
            <br />
            We help you go from good to great.
            <br />
            (A beginners’ class is coming soon.)
            <br />
            <br />
            Tuition: $800
            <br />
            Fully refundable until class begins.
            <br />
            <br />
            Student list will be finalized by April 7th.
            <br />
            If your work doesn’t meet our standards,
            <br />
            you’ll get a full refund.
            <br />
            <br />
            Top graduates will be invited to join
            <br />
            our platform, earning up to $14,000
            <br />
            per month by collaborating with founders.
            <br />
            <br />
            Because what’s education <br />
            without opportunity? We’re here to
            <br />
            give you both.
            <br />
            <br />
            See you in class.
          </p>
        </FadeSection>
      </div>

      <BottomNavigation
        className=""
        left={<BackButton native />}
        right={
          <Button size="sm" asChild>
            <Link to="/academy/story" resetScroll={true}>
              ?
            </Link>
          </Button>
        }
      />
    </main>
  );
}
