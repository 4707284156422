import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useState } from 'react';

import { cn } from '@/lib/utils';

export default function FeaturedBrands() {
  const [activeBrands, setActiveBrands] = useState(
    FEATURE_BRANDS_LOGOS.slice(0, 5),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBrands(prev => {
        const inactiveBrands = FEATURE_BRANDS_LOGOS.filter(
          logo => !prev.includes(logo),
        );

        const next = inactiveBrands.slice(0, 5);

        return next;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex w-full items-center justify-center gap-4 overflow-x-hidden">
      <AnimatePresence mode="popLayout">
        {activeBrands.map(logo => (
          <motion.div
            layout
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.2 }}
            key={logo}
            className="border-border flex size-14 items-center justify-center rounded-full border">
            <img
              src={logo}
              alt="Brand"
              className={cn('size-8 object-contain')}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}

export const FEATURE_BRANDS_LOGOS = [
  '/logos/bird.png',
  '/logos/zora.png',
  '/logos/nothing.png',
  '/logos/o.png',
  '/logos/amie.png',
  '/logos/plasticity.png',
  '/logos/krea.png',
  '/logos/opal.png',
  '/logos/mobbin.png',
  '/logos/thunder.png',
  '/logos/vfs.png',
];
