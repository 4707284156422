import '../chat.scss';

import { useEffect } from 'react';
import { Chat } from 'stream-chat-react';

import { IconClose } from '@/components/icons/icon-close';
import { OPLogo } from '@/components/op-logo';

import { chatState$ } from '../chat.state';
import { chatClient } from '../client';
import { useChatConnection } from '../use-chat-user';
import { useUnread } from '../use-unread';
import { AdminChat } from './admin-chat';
import { ProjectChat } from './project-chat';

export const ChatDialog = () => {
  const open = chatState$.dialogOpen.get();
  const chatType = chatState$.chatType.get();

  useChatConnection();
  useUnread();

  const renderChat = () => {
    if (!chatType) return null;

    switch (chatType) {
      case 'admin':
        return <AdminChat />;
      case 'project':
        return <ProjectChat />;
    }
  };

  useEffect(() => {
    return () => {
      chatClient.disconnectUser();
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const hasAttachmentViewOpened = document.querySelector(
        '.str-chat__modal--open',
      );

      if (event.key === 'Escape' && !hasAttachmentViewOpened) {
        chatState$.dialogOpen.set(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex w-full flex-col justify-end bg-[#FFFFFF14] backdrop-blur-[20px] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
      <header className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-gradient-to-t from-[transparent] to-[white] text-black">
        <a
          href="https://openpurpose.com"
          rel="noreferrer"
          className="mt-6 md:mt-10">
          <OPLogo />
        </a>
      </header>
      <div className="mx-auto h-full w-full">
        <button
          className="absolute right-5 top-5 z-30 rounded-full bg-black p-2 text-white"
          onClick={() => chatState$.dialogOpen.set(false)}>
          <IconClose />
        </button>
        <div className="mx-auto flex h-[100svh] w-full flex-col md:flex-row">
          <Chat client={chatClient}>{renderChat()}</Chat>
        </div>
      </div>
    </div>
  );
};
