import { observable } from '@legendapp/state';

export const chatState$ = observable({
  dialogOpen: false,
  channelId: null as string | null,
  chatType: () => {
    const channelId = chatState$.channelId.get();
    if (!channelId) {
      return null;
    }

    return channelId.startsWith('op-admins-chat') //
      ? 'admin'
      : 'project';
  },
  unreadCount: 0,
});

export const resetChatState = () => {
  chatState$.dialogOpen.set(false);
  chatState$.channelId.set(null);
};
