import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpc } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import {
  academyMemberCreateState$,
  isAcademyMemberCreateCompleted$,
} from '@/store/academy-member.state';

export const Route = createFileRoute('/new/academy/availability')({
  beforeLoad: () => {
    if (!isAcademyMemberCreateCompleted$.peek()) {
      return redirect({ to: '/new/academy' });
    }
  },
  component: function Page() {
    const availableForNewProjects =
      academyMemberCreateState$.availableForNewProjects.get();
    const navigate = Route.useNavigate();

    const saveMutation = trpc.academyMember.create.useMutation();

    const handleSubmit = async () => {
      const academyMember = academyMemberCreateState$.peek();

      await saveMutation.mutateAsync({
        email: academyMember.email,
        links: academyMember.links,
        samples: academyMember.samples,
        services: academyMember.services,
        name: academyMember.name,
        timeZone: academyMember.timeZone,
        availableForNewProjects: academyMember.availableForNewProjects!,
      });

      navigate({ to: '/new/academy/thank-you' });
    };

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1>
            Are you available to <br />
            take on new projects?
          </h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="single"
            defaultValue={String(availableForNewProjects!)}
            onValueChange={_value => {
              const value = _value === 'true';
              academyMemberCreateState$.availableForNewProjects.set(value);
            }}>
            <ToggleGroupItem value={'true'}>Yes</ToggleGroupItem>
            <ToggleGroupItem value={'false'}>No</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation
          left={<BackButton native />}
          middle={
            availableForNewProjects !== null && (
              <Button
                onClick={handleSubmit}
                disabled={saveMutation.isPending}
                loading={saveMutation.isPending}>
                Submit
              </Button>
            )
          }
        />
      </div>
    );
  },
});
